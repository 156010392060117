import {AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Brands} from "../brands";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {VehiculeService} from "../vehicule.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Agence} from "../../models/agence.model";
import {User} from "../../models/user.model";
import {AuthService} from "../../login/auth.service";
import {Subscription} from "rxjs/internal/Subscription";
import {environment} from "../../../environments/environment";
declare var jQuery:any;

@Component({
  selector: 'app-vehicule-add',
  templateUrl: './vehicule-add.component.html',
  styleUrls: ['./vehicule-add.component.css']
})
export class VehiculeAddComponent implements OnInit,AfterViewInit {

  constructor(private brands:Brands,private formBuilder:FormBuilder,private vehiculeService:VehiculeService,private router:Router,private eltRef:ElementRef,private http:HttpClient,private auth:AuthService) { }
    @Input() mark;
    @Input() lett;
    marques:any[];
    models:any[];
    agences:any[];
    vehiculeForm:FormGroup;
    vehiculeForm2:FormGroup;
    vehiculeForm3:FormGroup;
    vehiculeForm4:FormGroup;
    vehiculeForm5:FormGroup;
    vehiculeForm6:FormGroup;
    user:User;
    disable:boolean=true;
    activetab:boolean=false;
    message=null;
    idVehicule=null;
    error=null;
    submitedForm1:boolean;
    submitedForm3:boolean=false;
    submitedForm4:boolean=false;
    submitedForm5:boolean=false;
    submitedForm6:boolean=false;

    messageSubscription:Subscription;
    idVehiculeSubscription:Subscription;
    errorSubscription:Subscription;
    selectedfile1:File=null;
    selectedfile2:File=null;
    selectedfile3:File=null;
    selectedfile4:File=null;
    selectedfile5:File=null;
    selectedfile6:File=null;
    selectedfile7:File=null;
    selectedfile8:File=null;

  ngOnInit() {

    this.initForm();
    this.initForm2();
    this.initFormdoc();
    this.marques=this.brands.getBrands();
      this.user=this.auth.AuthUser();

      this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data2:any) => {
          this.agences = data2.agences;

      });
    this.errorSubscription=this.vehiculeService.errorSubject.subscribe((data)=>{
      this.error=data;
    });
    this.idVehiculeSubscription=this.vehiculeService.idSubject.subscribe((data)=>{
      this.idVehicule=data;
    });
    this.messageSubscription=this.vehiculeService.messageSubject.subscribe((data)=>{
      this.message=data;
    });

  }
  ngAfterViewInit(){
      jQuery(this.eltRef.nativeElement).ready(function() {




      });
  }
  refresh(){

      this.ngOnInit()


  }

    initForm(){

        this.vehiculeForm=this.formBuilder.group({
            name:'',
            num_chassis:['',Validators.required],
            energie:['',Validators.required],
            lettre:['',Validators.required],
            vil:['',[Validators.max(90)]],
            num:['',[Validators.required,Validators.max(1000000)]],
            boite_vitesse:['',[Validators.required]],
            marque:['',[Validators.required]],
            modele:['',[Validators.required]],
            portes:['',[Validators.max(7)]],
            passagers:['',[Validators.max(20)]] ,
            puissance_fiscal:['',Validators.required],
            climatise:false,
            couleur:['',[Validators.required]],
            serie_type:[''],
            user_id:'',
            agence_id:['',Validators.required],
            roue_secour:false,
            prix_particulier:['',Validators.required],
            prix_ste:['',Validators.required],
            num_cartegrise:['',Validators.required],
            info_comp:[''],
            remarque:[''],
            categorie:[''],
            prix_particulier_sem:['',Validators.required],
            prix_particulier_mo:['',Validators.required]
        });

    }

    initForm2(){

        this.vehiculeForm2=this.formBuilder.group({
            km_actuel:'',
            km_achat:[''],
            prix_achat:[''],
            prix_vente:[''],
            date_achat:[''],
            date_vente:['']
        });

    }
    initFormdoc(){

        this.vehiculeForm3=this.formBuilder.group({
            type:'carte_grise',
            num_piece:['',[Validators.required]],
            date_obtention:[''],
            date_expiration:['',[Validators.required]],
            vehicule_id:[''],

        })
        ;this.vehiculeForm4=this.formBuilder.group({
            type:'assurance',
            num_piece:['',[Validators.required]],
            date_obtention:[''],
            date_expiration:['',[Validators.required]],
            vehicule_id:[''],
        });
        this.vehiculeForm5=this.formBuilder.group({
            type:'visite_technique',
            num_piece:['',[Validators.required]],
            date_obtention:[''],
            date_expiration:['',[Validators.required]],
            vehicule_id:[''],
        });
        this.vehiculeForm6=this.formBuilder.group({
            type:'certif_conformite',
            num_piece:['',[Validators.required]],
            date_obtention:[''],
            date_expiration:['',[Validators.required]],
            vehicule_id:[''],
        });

    }

    activeTab(){
      this.activetab=!this.activetab;
    }

    disableNum(num){
    if(num==="WW"){
      this.disable=false;
    }
    else{
      this.disable=true;
    }
    }
    getModels(brand){
         this.models = this.marques.find(function(element) {
            return element.brand === brand;
        }).models;

    }

    onSubmitform(){
    this.submitedForm1=true;
    this.vehiculeForm.value['user_id']=this.user.id;
        if(this.vehiculeForm.value['vil'] && !(this.vehiculeForm.value['lettre']=='WW')){
        this.vehiculeForm.value['name']=this.vehiculeForm.value['marque'].toUpperCase()+' '+this.vehiculeForm.value['modele'].toUpperCase()+' '+this.vehiculeForm.value['num'].toString()+' '+this.vehiculeForm.value['lettre']+' '+this.vehiculeForm.value['vil'].toString();
        console.log(this.vehiculeForm.value['name']);
        }
        if(this.vehiculeForm.value['lettre']==='WW'){
            this.vehiculeForm.value['name']=this.vehiculeForm.value['marque'].toUpperCase()+' '+this.vehiculeForm.value['modele'].toUpperCase()+' '+this.vehiculeForm.value['num'].toString()+' '+this.vehiculeForm.value['lettre'];
            console.log(this.vehiculeForm.value['name']);

        }
      if(this.submitedForm1){
          if(this.vehiculeForm.valid){
              this.error=null;
      this.vehiculeService.addVehicule(this.vehiculeForm.value);

        if(!this.error){
          setTimeout(()=>{this.message=null;
                                  },2000);
          this.submitedForm1=false;

        }
        }
        else{

              this.error=null;
              this.vehiculeService.editVehicule(this.vehiculeForm.value,this.idVehicule);

              if(!this.error){
                  setTimeout(()=>{this.message=null;
                  },2000);
                  this.submitedForm1=false;

              }
          }
        }

    }
    NonNullForm(value) {
        const val = [value];

        if (val) {

            let filledProps = (val).map(el => {
                // Loop the property names of `el`, creating a new object
                // with the ones whose values aren't `null`.
                // `reduce` is commonly used for doing this:
                return Object.keys(el).reduce((newObj, key) => {
                    const value = el[key];
                    if (value !== "") {
                        newObj[key] = value;
                    }
                    return newObj;
                }, {});
            });
            return filledProps;
        }
        return null
    }

    onSubmitform2(){
      console.log(this.NonNullForm(this.vehiculeForm2.value));
      this.vehiculeService.editVehicule(this.NonNullForm(this.vehiculeForm2.value)[0],this.idVehicule);
    }
    onSubmitForm3(){

        const value3=this.vehiculeForm3.value;
        const value4=this.vehiculeForm4.value;
        const value5=this.vehiculeForm5.value;
        const value6=this.vehiculeForm6.value;
        value3['vehicule_id']=this.idVehicule;
        value4['vehicule_id']=this.idVehicule;
        value5['vehicule_id']=this.idVehicule;
        value6['vehicule_id']=this.idVehicule;
        if(this.vehiculeForm3.valid && !this.submitedForm3){


            this.vehiculeService.addDoc(value3,this.selectedfile1,this.selectedfile2);
            this.submitedForm3=true;
        }
        if(this.submitedForm3){
            this.vehiculeService.editDoc(this.NonNullForm(value3),this.idVehicule,this.selectedfile1,this.selectedfile2)
        }
        if(this.vehiculeForm4.valid && !this.submitedForm4){


            this.vehiculeService.addDoc(value4,this.selectedfile3,this.selectedfile4);
            this.submitedForm4=true;
        }
        if(this.submitedForm4){
            this.vehiculeService.editDoc(this.NonNullForm(value4),this.idVehicule,this.selectedfile3,this.selectedfile4)
        }
        if(this.vehiculeForm5.valid && !this.submitedForm5){


            this.vehiculeService.addDoc(value5,this.selectedfile5,this.selectedfile6);
            this.submitedForm5=true;
        }
        if(this.submitedForm5){
            this.vehiculeService.editDoc(this.NonNullForm(value5),this.idVehicule,this.selectedfile5,this.selectedfile6)
        }
        if(this.vehiculeForm6.valid && !this.submitedForm6){


            this.vehiculeService.addDoc(value6,this.selectedfile7,this.selectedfile8);
            this.submitedForm6=true;
        }
        if(this.submitedForm6){
            this.vehiculeService.editDoc(this.NonNullForm(value6),this.idVehicule,this.selectedfile7,this.selectedfile8)
        }

    }

    onFileSelected(event){
        let file1: File = event.target.files[0];
        this.selectedfile1=file1;
        console.log(this.selectedfile1.name)
    }

    onFileSelected2(event){
        let file1: File = event.target.files[0];
        this.selectedfile2=file1;
        console.log(this.selectedfile2.name)
    }

    onFileSelected3(event){
        let file1: File = event.target.files[0];
        this.selectedfile3=file1;
        console.log(this.selectedfile3.name)
    }

    onFileSelected4(event){
        let file1: File = event.target.files[0];
        this.selectedfile4=file1;
        console.log(this.selectedfile4.name)
    }

    onFileSelected5(event){
        let file1: File = event.target.files[0];
        this.selectedfile5=file1;
        console.log(this.selectedfile5.name)
    }

    onFileSelected6(event){
        let file1: File = event.target.files[0];
        this.selectedfile6=file1;
        console.log(this.selectedfile6.name)
    }
    onFileSelected7(event){
        let file1: File = event.target.files[0];
        this.selectedfile7=file1;
        console.log(this.selectedfile7.name)
    }
    onFileSelected8(event){
        let file1: File = event.target.files[0];
        this.selectedfile8=file1;
        console.log(this.selectedfile8.name)
    }
}
