import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {User} from "../models/user.model";
import {Subscription} from "rxjs/internal/Subscription";
import {UserService} from "./user.service";
import {HttpClient} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../environments/environment";
declare var jQuery:any;
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})


export class UserComponent implements OnInit ,AfterViewInit{

    userschecked:any[]=[];
    @Input() check:any;
    interests:any[];
    users:any[];
    count:number=0;
    message:null;
    error:null;
    errorSubscription:Subscription;
    messageSubscription:Subscription;
  constructor(private userService:UserService,private eltRef:ElementRef,private http:HttpClient,private ngZone:NgZone,private formBuilder:FormBuilder) {
      jQuery(this.eltRef.nativeElement).ready(function() {

      });
  }


  ngOnInit() {

      this.errorSubscription=this.userService.errorSubject.subscribe((error)=>{
          this.error=error;
      });
      this.messageSubscription=this.userService.messageSubject.subscribe((message)=>{
          this.message=message;
      });
      setTimeout(()=>{
          this.message=null;
          this.error=null;
      },3000);


  }
   checkAll(event) {
       if (event.target.checked) {
        var checked=[];
       jQuery(".checkboxes").each(function () {
                checked.push(parseInt(jQuery(this).val()))

       });
           this.userschecked=checked;
       }
       if (!event.target.checked){
           this.userschecked=[];
       }
       console.log(this.userschecked);
   }
    onCheckboxChagen(event, value) {

        if (!event.target.checked) {
            let index = this.userschecked.indexOf(value);
            if (index > -1) {
                this.userschecked.splice(index, 1);
            }
        }
        if (event.target.checked) {
            this.userschecked.push(value);
        }

    }
    onDelete(){
      let tab=this.userschecked;
      if(confirm("Voulez vous vraiment Supprimer ces Utilisateurs")){
      for(let i=0;i<tab.length;i++){
          this.userService.delete(tab[i]);
      }
      this.refresh();
      }
    }

    onActivate(id,value){
        let status={disabled:value};
        this.userService.editUser(status,id);
        this.refresh();
    }




    ngAfterViewInit(){
        this.http.get(`${environment["API_URL"]}`+'/users').subscribe((data:any) => {
            this.users = data.users;
            this.count=this.users.length;
            console.log(data.users);
            // waiting until select options are rendered
            jQuery("#sample_1").dataTable().fnDestroy();
            this.ngZone.onMicrotaskEmpty.subscribe(() => {
               // App.init();
                //FormWizard.init();
                //TableManaged.init();

                jQuery(this.eltRef.nativeElement).ready(function() {
                    jQuery.fn.dataTable.ext.errMode = 'none';

                    jQuery('#sample_1').on( 'error.dt', function ( e, settings, techNote, message ) {
                        console.log( 'An error has been reported by DataTables: ', message );
                    } ) ;
                });

            });
        });
}

    refresh(){
        this.message=null;
        this.error=null;
        this.ngAfterViewInit();

    }
    private createDiv(node_name,textElement) {
        var _nodeElement = document.createElement(node_name);
        _nodeElement.innerHTML = textElement;
        return _nodeElement;
    }




}
