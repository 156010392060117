import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../../models/user.model";
import {Subscription} from "rxjs/internal/Subscription";
import {UserService} from "../user.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit,AfterViewInit {
    users:User[];
    roles:any[];
    agences:any[];
    selectedfile:File;
    formUser:FormGroup;
    message:null;
    error:null;

    errorSubscription:Subscription;
    messageSubscription:Subscription;
  constructor(private userService:UserService,private http:HttpClient,private formBuilder:FormBuilder,private ngZone:NgZone) { }

   ngAfterViewInit(){


   }
  ngOnInit() {
      this.initForm();
      this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data2:any) => {
          this.agences = data2.agences;
          console.log(this.agences);
      });
      this.http.get(`${environment["API_URL"]}`+'/roles').subscribe((data:any) => {
          this.roles = data.roles;
          console.log(this.roles);
      });
      this.errorSubscription=this.userService.errorSubject.subscribe((error)=>{
          this.error=error;
      });
      this.messageSubscription=this.userService.messageSubject.subscribe((message)=>{
          this.message=message;
      });

          //FormWizard.init();


  }
    onFileSelected(event){
        let file: File = event.target.files[0];
        this.selectedfile=file;

    }
    initForm(){

        this.formUser=this.formBuilder.group({
            name:['',Validators.required],
            prenom:['',Validators.required],
            remarque:'',
            fonction:'',
            password:[''],
            sexe:'',
            email:['',[Validators.required,Validators.email]],
            disabled:false ,
            ville:['',Validators.required],
            pays:[''],
            cin:[''],
            birthday:[''],
            role_id:'',
            agence_id:['',Validators.required],
            tel:[''],
            adresse:['',Validators.required]
        })
    }
    onSubmitform(){

        console.log(this.formUser.value);
        this.userService.addUser(this.formUser.value,this.selectedfile);


    }
}
