import { Injectable } from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {User} from "../models/user.model";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {TokenService} from "./token.service";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    private url=`${environment["API_URL"]}`;
    private errors:any;
    public errorSubject=new Subject<any>();
    private messages:any;
    public messageSubject=new Subject<any>();


    private loggedIn=new BehaviorSubject<boolean>(this.token.LoggedIn());
    authStatus=this.loggedIn.asObservable();

    constructor(private http:HttpClient,private router:Router,private token:TokenService){
    }

    emitError(){
      this.errorSubject.next(this.errors);
    }

    emitMessage(){
      this.messageSubject.next(this.messages);
    }

    signin(credentials) {
        this.http.post(this.url+'/auth/login',credentials).subscribe((data)=>{
          this.handleResponse(data);

        },(error)=>{
          this.errors=error;
          this.emitError();
        })
    }

    resetPassword(credentials){
        this.http.post(this.url+'/resetPassword',credentials).subscribe((data)=>{
            this.messages=data;
            this.emitMessage();
        },
            (error)=>{
            this.errors=error;
            this.emitError();
            });
    }
    handleResponse(data){
        this.token.handle(data.access_token);
        localStorage.setItem('user',JSON.stringify(data.user));
        this.changeAuthStatus(true);
        this.router.navigate(['']);
    }
    AuthUser(){
       return JSON.parse(localStorage.getItem('user'));
    }
    removeAuthUser(){
        return localStorage.removeItem('user');
    }

    changeAuthStatus(value:boolean){
        this.loggedIn.next(value);
    }

    changePassword(form) {
        this.http.post(this.url+'/changePassword',form).subscribe((data)=>{
                this.messages=data;
                this.errors=null;
                this.emitError();
                this.emitMessage();
                this.signin(form);
            },
            (error)=>{
                this.errors=error;
                this.emitError();
            });
    }
}
