import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject} from "rxjs/internal/Subject";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class TiersService {

    

    private tierss:any[];
    private tiers:any;
    private doc:any;
    tiersSubject=new Subject<any[]>();
    messageSubject =new Subject<any>();
    idSubject =new Subject<any>();
    errorSubject =new Subject<any>();
    private message:string;
    private idtiers:number;
    private error:string;
    private url=`${environment["API_URL"]}`;
    constructor(private http:HttpClient,private router:Router) { }

    emittierss(){
        this.tiersSubject.next(this.tierss);
    }
    emitMessage(){
        this.messageSubject.next(this.message);
    }
    emitId(){
        this.idSubject.next(this.idtiers);
    }
    emitError(){
        this.errorSubject.next(this.error);
    }


    gettierss(){
        this.http.get(this.url+'/tierss').subscribe((data:any[])=>{
            this.tierss=data;
            this.emittierss();
            console.log(this.tierss);
        });
    }
    delete(id){
        this.http.delete(this.url+'/tierss/delete/'+id).subscribe((data:any)=>{
            this.message=data;
            this.emitMessage();
        });
    }
    deleteDocument(id){
        this.http.delete(this.url+'/tierss/document/delete/'+id).subscribe((data:any)=>{
            this.message=data;
            this.emitMessage();
        });
    }
    deletePhoto(id){
        this.http.delete(this.url+'/tiers/photos/delete/'+id).subscribe((data:any)=>{
            this.message=data;
            this.emitMessage();
        });
    }

    changePhoto(file: File, id: number): void {
        if (file) {
          const formData: FormData = new FormData();
          formData.append('photo', file, file.name);
    
          this.http.post(`${environment.API_URL}/tierss/updateImage/${id}`, formData)
            .subscribe(
              data => {
                console.log('Success:', data);
              },
              error => {
                console.error('Error:', error);
              }
            );
        }
      }

    removePhoto(id){

        this.http.get(`${environment["API_URL"]}`+'/tierss/removeImage/'+id)
            .subscribe(
                (data:any) => {
                    this.message=data.message;
                    this.emitMessage();
                    console.log(data)},
                error => console.log(error)
            );

    }

    addDoc(value,img_recto,img_verso){


        this.http.post(`${environment["API_URL"]}`+'/tierss/document/add',value).subscribe((data:any)=>
        {
            this.doc= data;
            this.message=data.message;

            this.emitMessage();

            if(data){
                const id=data.document.id;
                if(img_recto){
                    let formData:FormData = new FormData();
                    formData.append('image_recto', img_recto, img_recto.name);

                    this.http.post(`${environment.API_URL}/tierss/document/recto/${id}`, formData)
                    .subscribe(
                      data => {
                        console.log('Success:', data);
                      },
                      error => {
                        console.error('Error:', error);
                      }
                    );
                }

                if(img_verso){
                    let formData2:FormData = new FormData();
                    formData2.append('image_verso', img_verso, img_verso.name);

                    this.http.post(`${environment.API_URL}/tierss/document/verso/${id}`, formData2)
                    .subscribe(
                      data => {
                        console.log('Success:', data);
                      },
                      error => {
                        console.error('Error:', error);
                      }
                    );

                }
            }

        },error => {
            this.error=error;
            this.emitError();

        });



    }




    addtiers(tiers){
        this.http.post(`${environment["API_URL"]}`+'/tierss/add',tiers).subscribe((data:any)=>
        {
            this.idtiers= data.tiers_id;
            this.message=data.message;
            this.emitId();
            this.emitMessage();




        },error => {
            this.error=error.error;
            this.emitError();


        });

    }
    /*  chi7aja(){
          if(data){
              tiers.id=data.tiers.id;
  
              if(file){
                  let formData:FormData = new FormData();
                  formData.append('photo', file, file.name);
  
                  let headers = new Headers();
                  headers.append('Accept', 'application/json');
                  headers.append('Authorization','Bearer ' + localStorage.token );
                  let options = new HttpHeaders({ headers: headers });
                  this.http.post('http://127.0.0.1:8000/api/tierss/updateImage/'+data.tiers.id, formData, options)
                      .subscribe(
                          data => {
  
                              console.log('success')},
                          error => console.log(error)
                      )
              }}
      }
          */
    edittiers(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };

        this.http.put(`${environment["API_URL"]}`+'/tierss/update/'+id ,value,options).subscribe((data:any)=>{
            console.log(data);
            this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }
    editDoc(value,id,img_recto,img_verso){

        this.http.put(`${environment["API_URL"]}`+'/tierss/document/update/'+id,value).subscribe((data:any)=>
        {
            this.doc= data;
            this.message=data.message;

            this.emitMessage();

            if(data){

                if(img_recto){
                    let formData:FormData = new FormData();
                    formData.append('image_recto', img_recto, img_recto.name);

                    const headers = new HttpHeaders({
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.token
                      });
                
                      this.http.post(`${environment.API_URL}/tierss/document/recto/${id}`, formData, { headers })
                        .subscribe(
                          () => {
                            console.log('Success');
                          },
                          error => {
                            console.error('Error:', error);
                          }
                        );
                }

                if(img_verso){
                    let formData2:FormData = new FormData();
                    formData2.append('image_verso', img_verso, img_verso.name);

                    const headers = new HttpHeaders({
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.token
                      });
                
                      this.http.post(`${environment.API_URL}/tierss/document/verso/${id}`, formData2, { headers })
                        .subscribe(
                          () => {
                            console.log('Success');
                          },
                          error => {
                            console.error('Error:', error);
                          }
                        );


                }
            }

        },error => {
            this.error=error;
            this.emitError();

        });




    }
    deleteContact(id){
        this.http.delete(this.url+'/tierss/contact/delete/'+id).subscribe((data:any)=>{
            this.message=data;
            this.emitMessage();
        });
    }
    addCon(value){

        this.http.post(`${environment["API_URL"]}`+'/tierss/contact/add',value).subscribe((data:any)=>
        {

            this.message=data.message;

            this.emitMessage();

        });
    }

    editCon(id,value){

            this.http.put(`${environment["API_URL"]}`+'/tierss/contact/update/'+id,value).subscribe((data:any)=>
            {

                this.message=data.message;

                this.emitMessage();

               });}

}
