import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject} from "rxjs/internal/Subject";
import {environment} from "../../../environments/environment";
@Injectable({
    providedIn: 'root'
})
export class VidangeService {
    private vidanges:any[];
    private vidange:any;
    vidangeSubject=new Subject<any[]>();
    messageSubject =new Subject<any>();
    errorSubject =new Subject<any>();
    private message:string;
    private error:string;
    private url=`${environment["API_URL"]}`;
    constructor(private http:HttpClient) { }

    emitvidanges(){
        this.vidangeSubject.next(this.vidanges);
    }
    emitMessage(){
        this.messageSubject.next(this.message);
    }

    emitError(){
        this.errorSubject.next(this.error);
    }


    getvidanges(){
        this.http.get(this.url+'/vidanges').subscribe((data:any[])=>{
            this.vidanges=data;
            this.emitvidanges();
            console.log(this.vidanges);
        });
    }
    delete(id){
        this.http.delete(this.url+'/vidanges/delete/'+id).subscribe((data:any)=>{
            this.message=data;
            this.emitMessage();
        });
    }





    addvidange(vidange){
        this.http.post(`${environment["API_URL"]}`+'/vidanges/add',vidange).subscribe((data:any)=>
        {
            this.vidange= data;
            this.message=data.message;

            this.emitMessage();



        },error => {
            this.error=error;
            this.emitError();

        });

    }

    editvidanget(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/vidanges/updaten/'+id ,value,options).subscribe((data:any)=>{
            this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }
    editvidange(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/vidanges/update/'+id ,value,options).subscribe((data:any)=>{
            this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }
}
