import {Component, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";
import {AssuranceService} from "./assurance.service";
import {FormBuilder} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";


declare var jQuery:any;
@Component({
  selector: 'app-assurance',
  templateUrl: './assurance.component.html',
  styleUrls: ['./assurance.component.css']
})
export class AssuranceComponent implements OnInit {

    assuranceschecked:any[]=[];
    @Input() check:any;
    @Input() vehicule_id:number;
    @Input() user_id:number;
    interests:any[];
    assurances:any[];
    agence_id:number;
    agence:any;
    vehicule:any;
    editAss:boolean=false;
    assurancesLoaded: Promise<boolean>;
    count:number=0;
    message:null;
    error:null;
    assurance_id:null;
    vehicul_id:null;
    agenc_id:null;

    errorSubscription:Subscription;
    messageSubscription:Subscription;
    constructor(private assuranceService:AssuranceService,private eltRef:ElementRef,private http:HttpClient,private ngZone:NgZone,private formBuilder:FormBuilder) {

    }


    ngOnInit() {

        this.errorSubscription=this.assuranceService.errorSubject.subscribe((error)=>{
            this.error=error;
        });
        this.messageSubscription=this.assuranceService.messageSubject.subscribe((message)=>{
            this.message=message;
            if(this.message){
                setTimeout(()=>{this.ngOnInit()},1000);
            }
        });
        setTimeout(()=>{
            this.message=null;
            this.error=null;
        },3000);
        this.getAssurances();
        this.assuranceschecked=[];

    }

    getAssurances(){
        if(this.vehicule_id){
        this.http.get(`${environment["API_URL"]}`+'/vehicules/'+this.vehicule_id).subscribe((data:any) => {
            this.assurances = data.assurances;
            this.agence=data.agence;
            this.vehicule=data.Vehicule;
            this.count=this.assurances? this.assurances.length :0;
            this.agence_id=this.agence.id;
            console.log(this.assurances);
            console.log(this.agence);
            console.log(this.vehicule);
            jQuery("#sample_7").dataTable().fnDestroy();
            jQuery("#sample_6").dataTable().fnDestroy();

            this.ngZone.onMicrotaskEmpty.subscribe(() => {
                // App.init();
                //FormWizard.init();
                //TableManaged.init();

                jQuery(this.eltRef.nativeElement).ready(function() {
                    jQuery.fn.dataTable.ext.errMode = 'none';

                    jQuery('#sample_7').on( 'error.dt', function ( e, settings, techNote, message ) {
                        console.log( 'An error has been reported by DataTables: ', message );
                    } ) ;
                });

            });


        });}
        else{
        this.http.get(`${environment["API_URL"]}`+'/assurances').subscribe((data:any) => {

            this.assurances = data.Assurances;
                jQuery("#sample_7").dataTable().fnDestroy();


                this.ngZone.onMicrotaskEmpty.subscribe(() => {
                    // App.init();
                    //FormWizard.init();
                    //TableManaged.init();

                    jQuery(this.eltRef.nativeElement).ready(function() {
                        jQuery.fn.dataTable.ext.errMode = 'none';

                        jQuery('#sample_7').on( 'error.dt', function ( e, settings, techNote, message ) {
                            console.log( 'An error has been reported by DataTables: ', message );
                        } ) ;
                    });

                });

            this.count=this.assurances? this.assurances.length :0;

                // waiting until select options are rendered

            },
            ()=>{
                this.assurances=null;
            });
        }



    }
    checkAll(event) {
        if (event.target.checked) {
            var checked=[];
            jQuery(".checkboxes").each(function () {
                checked.push(parseInt(jQuery(this).val()))

            });
            this.assuranceschecked=checked;
        }
        if (!event.target.checked){
            this.assuranceschecked=[];
        }
        console.log(this.assuranceschecked);
    }
    onCheckboxChagen(event, value) {

        if (!event.target.checked) {
            let index = this.assuranceschecked.indexOf(value);
            if (index > -1) {
                this.assuranceschecked.splice(index, 1);
            }
        }
        if (event.target.checked) {
            this.assuranceschecked.push(value);
        }

    }
    onDelete(){
        let tab=this.assuranceschecked;
        if(confirm("Voulez vous vraiment Supprimer ces assurances")){
            for(let i=0;i<tab.length;i++){
                this.assuranceService.delete(tab[i]);
            }
            this.editAss=false;
            this.refresh();
        }
    }

    onActivate(id,value){
        let status={disabled:value};
        this.assuranceService.editassurance(status,id);
        this.refresh();
    }




    ngAfterViewInit(){


    }

    refresh(){
        this.message=null;
        this.error=null;
        this.ngOnInit();

    }

    editass(idass){

    this.assurance_id=idass;


    this.editAss=true;
        document.getElementById('paiment').scrollIntoView({behavior: "smooth"});

    }

    changeAssurance(data){
        this.assurance_id=data;
    }


}
