import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {LoginComponent} from "./login/login.component";
import {AuthGuardService} from "./services/auth-guard.service";
import {AuthGuardBeforeService} from "./services/auth-guard-before.service";
import {ChangePasswordComponent} from "./login/change-password/change-password.component";
import {UserComponent} from "./user/user.component";
import {AgenceComponent} from "./user/agence/agence.component";
import {AgenceShowComponent} from "./user/agence/agence-show/agence-show.component";
import {AdministrationComponent} from "./administration/administration.component";
import {UserShowComponent} from "./user/user-show/user-show.component";
import {VehiculeComponent} from "./vehicule/vehicule.component";
import {VehiculeAddComponent} from "./vehicule/vehicule-add/vehicule-add.component";
import {VehiculeShowComponent} from "./vehicule/vehicule-show/vehicule-show.component";
import {AgenceAddComponent} from "./user/agence/agence-add/agence-add.component";
import {UserAddComponent} from "./user/user-add/user-add.component";
import {AssuranceComponent} from "./vehicule/assurance/assurance.component";
import {PieceConsommableComponent} from "./vehicule/piece-consommable/piece-consommable.component";
import {ReparationComponent} from "./vehicule/reparation/reparation.component";
import {VidangeComponent} from "./vehicule/vidange/vidange.component";
import {VignetteComponent} from "./vehicule/vignette/vignette.component";
import {VisiteTechniqueComponent} from "./vehicule/visite-technique/visite-technique.component";
import {VisiteAddComponent} from "./vehicule/visite-technique/visite-add/visite-add.component";
import {VignetteAddComponent} from "./vehicule/vignette/vignette-add/vignette-add.component";
import {AssuranceAddComponent} from "./vehicule/assurance/assurance-add/assurance-add.component";
import {VidangeAddComponent} from "./vehicule/vidange/vidange-add/vidange-add.component";
import {ReparationAddComponent} from "./vehicule/reparation/reparation-add/reparation-add.component";
import {PieceConsommableAddComponent} from "./vehicule/piece-consommable/piece-consommable-add/piece-consommable-add.component";
import {TiersComponent} from "./tiers/tiers.component";
import {TiersShowComponent} from "./tiers/tiers-show/tiers-show.component";
import {TiersAddComponent} from "./tiers/tiers-add/tiers-add.component";
import {ReservationComponent} from "./reservation/reservation.component";
import {ReservationAddComponent} from "./reservation/reservation-add/reservation-add.component";
import {LieuxAgenceComponent} from "./user/agence/lieux-agence/lieux-agence.component";


const routes: Routes = [{path:'login',component:LoginComponent,canActivate:[AuthGuardBeforeService]},{path:'changePassword',component:ChangePasswordComponent,canActivate:[AuthGuardBeforeService]},
    {path:'',component:HomeComponent,data:{ breadcrumb: 'Acceuil'},canActivate:[AuthGuardService],children:[
            {path:'users',component:UserComponent,data:{ breadcrumb: 'Utilisateurs'}},
            {path:'users/add',component:UserAddComponent,data:{ breadcrumb: ' Nouveau'}},
            {path:'users/:id',component:UserShowComponent,data:{ breadcrumb: ' Show'}},

            {path:'administration',component:AdministrationComponent,data:{ breadcrumb: 'Administration'}},
            {path:'agences',component:AgenceComponent,data:{ breadcrumb: 'Agences'}},
            {path:'agences/add',component:AgenceAddComponent,data:{ breadcrumb: 'Nouvelle'}},
            {path:'agences/lieux',component:LieuxAgenceComponent,data:{ breadcrumb: 'Lieux de Livraison'}},
            {path:'agences/:id',component:AgenceShowComponent,data:{ breadcrumb: 'Agence'}},
            {path:'vehicules',component:VehiculeComponent,data:{ breadcrumb: 'Vehicules'}},
            {path:'vehicules/add',component:VehiculeAddComponent,data:{ breadcrumb: 'Nouveau'}},
            {path:'vehicules/assurances',component:AssuranceComponent,data:{ breadcrumb: 'Assurance'}},
            {path:'vehicules/addassurance',component:AssuranceAddComponent,data:{ breadcrumb: 'Assurer un Vehicule '}},
            {path:'vehicules/piecesconsommable',component:PieceConsommableComponent,data:{ breadcrumb: 'Pieces Consommable'}},
            {path:'vehicules/addpiecesconsommable',component:PieceConsommableAddComponent,data:{ breadcrumb: 'Pieces Consommable'}},
            {path:'vehicules/reparations',component:ReparationComponent,data:{ breadcrumb: 'Reparations'}},
            {path:'vehicules/addreparation',component:ReparationAddComponent,data:{ breadcrumb: 'Reparations'}},
            {path:'vehicules/vidanges',component:VidangeComponent,data:{ breadcrumb: 'Vidange'}},
            {path:'vehicules/addvidanges',component:VidangeAddComponent,data:{ breadcrumb: 'Vidange'}},
            {path:'vehicules/vignettes',component:VignetteComponent,data:{ breadcrumb: 'Vignette'}},
            {path:'vehicules/vignetteadd',component:VignetteAddComponent,data:{ breadcrumb: 'Regler une Vignette'}},
            {path:'vehicules/visites',component:VisiteTechniqueComponent,data:{ breadcrumb: 'Visite technique'}},
            {path:'vehicules/visiteadd',component:VisiteAddComponent,data:{ breadcrumb: 'Effectuer une Visite technique'}},
            {path:'vehicules/:id',component:VehiculeShowComponent,data:{ breadcrumb: 'Vehicule'}},
            {path:'tiers',component:TiersComponent,data:{ breadcrumb: 'Tiers'}},
            {path:'tiers/add',component:TiersAddComponent,data:{ breadcrumb: 'Tiers'}},
            {path:'tiers/:id',component:TiersShowComponent,data:{ breadcrumb: 'Tiers'}},
            {path:'reservations',component:ReservationComponent,data:{ breadcrumb: 'Reservations'}},
            {path:'reservations/add',component:ReservationAddComponent,data:{ breadcrumb: 'Nouvelle Reservation'}},




        ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {
      onSameUrlNavigation: 'reload',
      enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
