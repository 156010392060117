import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {ActivatedRoute} from "@angular/router";
import {AgenceService} from "../agence/agence.service";
import {Subscription} from "rxjs/internal/Subscription";
import {User} from "../../models/user.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../user.service";
import {TopNavbarComponent} from "../../top-navbar/top-navbar.component";
import {Location} from "@angular/common";
import {EventEmitterService} from "../../event-emitter.service";
import {AuthService} from "../../login/auth.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-user-show',
  templateUrl: './user-show.component.html',
  styleUrls: ['./user-show.component.css']
})
export class UserShowComponent implements OnInit {

    constructor(private activRoute:ActivatedRoute,private authService:AuthService,private EvnetEmitterService:EventEmitterService,private agenceservice:AgenceService,private userService:UserService,private http:HttpClient,private formBuilder:FormBuilder,private _location:Location) { }


    user:any;
    imageToShow: any;
    agences:any[];
    roles:any[];
    RoleAgences:any[];
    roleRights:any[];
    selectedValue:any;
    selectedfile:File;
    formUserUpdate:FormGroup;
    formUserUpdate1:FormGroup;
    id=null;
    messageSub:Subscription;
    errorSub:Subscription;
    message:null;
    error:null;
    oninit:boolean=true;


    ngOnInit() {
        const idauth=this.authService.AuthUser().id;
        this.activRoute.params.subscribe((params)=>{
            this.id=params['id'];
            if(idauth===+this.id){
                if(this.oninit) {
                    this.oninit=false;
                   this.ngOnInit();

                }
            }

        });

        console.log();
        this.http.get(`${environment["API_URL"]}`+'/users/'+this.id).subscribe(
            (data:any)=>{
                this.user= data ;


            },
            (error)=>{
                console.log(error);
            }
        );
        this.getImage(`${environment["API_URL"]}`+'/usersPhoto/'+this.id).subscribe((data)=>{
            this.createImageFromBlob(data);
            console.log(data);
        },(error)=>{
            this.createImageFromBlob(null);
            this.imageToShow=null;
        });
        this.getAgences();
        this.getRoles();

        this.messageSub=this.userService.messageSubject.subscribe((data)=>{
            this.message=data;

        });
        this.errorSub=this.userService.errorSubject.subscribe((data)=>{
            this.error=data;
        });
        this.initForm();
        this.initForm2();


    }


    getImage(imageUrl: string): Observable<Blob> {
        return this.http.get(imageUrl, { responseType: 'blob' });
    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.imageToShow = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }
    getAgences(){

        this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data:any)=> {
            this.agences = data.agences;

        });


    }
    getRoles(){

        this.http.get(`${environment["API_URL"]}`+'/roles').subscribe((data:any)=> {
            this.roles = data.roles;
            console.log(data);

        });
    }
    getRoleAgences(value){
        this.RoleAgences=this.roles[value].agence;
        this.roleRights=this.roles[value].rights;
    }

    onFileSelected(event){
        let file: File = event.target.files[0];
        this.selectedfile=file;
    }

    getback(){
        this._location.back();
    }

    initForm(){

        this.formUserUpdate=this.formBuilder.group({
            name:'',
            prenom:'',
            remarque:'',
            fonction:'',
            email:'',
            ville:'',
            pays:'',
            cin:'',
            birthday:'',
            agence_id:'',
            tel:'',
            adresse:'',
        })
    }
    initForm2(){
        this.formUserUpdate1=this.formBuilder.group({
            rpassword:'',
            password:'',
            PasswordActuel:''
        })
    }

    NonNullForm(value) {
        const val = [value];

        if (val) {

            let filledProps = (val).map(el => {
                // Loop the property names of `el`, creating a new object
                // with the ones whose values aren't `null`.
                // `reduce` is commonly used for doing this:
                return Object.keys(el).reduce((newObj, key) => {
                    const value = el[key];
                    if (value !== "") {
                        newObj[key] = value;
                    }
                    return newObj;
                }, {});
            });
            return filledProps;
        }
        return null
    }


    removephoto(){
        if(this.imageToShow){
        if(confirm("êtes-vous sûr de vouloir supprimer la photo de profile")){

            this.userService.removePhoto(this.user.user.id);
        }}
        else{
            alert("Vous n'avez aucune image de profile a supprimer");
        }

    }



    onSubmitForm(){
        const value=this.NonNullForm(this.formUserUpdate.value)[0];
        if(value){

            this.userService.editUser(value,this.user.user.id);
            //setTimeout(this.ngOnInit(),100);
        }
    }
    onSubmitForm1(){

        const value=this.NonNullForm(this.formUserUpdate1.value)[0];
        if(value){

            this.userService.editUser(value,this.user.user.id);
            //setTimeout(this.ngOnInit(),100);

        }

    }
    changePhoto(){
        this.userService.changePhoto(this.selectedfile,this.user.user.id);
        this.EvnetEmitterService.onChangePhoto();
        this.EvnetEmitterService.onChangePhoto();

        this.recharger();
        this.recharger();

    }
    recharger(){

        this.ngOnInit();
    }
    refreshForm(){

        this.initForm();
    }

}
