import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit} from '@angular/core';
//import {Vehicule} from "../models/Vehicule.model";
import {Subscription} from "rxjs/internal/Subscription";
import {VehiculeService} from "./vehicule.service";
import {HttpClient} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../environments/environment";
declare var jQuery:any;
@Component({
    selector: 'app-Vehicule',
    templateUrl: './vehicule.component.html',
    styleUrls: ['./vehicule.component.css']
})
export class VehiculeComponent implements OnInit ,AfterViewInit{
    count:number;
    vehiculeschecked:any[]=[];
    @Input() check:any;
    interests:any[];
    vehicules:any[];
    roles:any[];
    agences:any[];
    selectedfile:File;
    formVehicule:FormGroup;
    message:null;
    error:null;

    errorSubscription:Subscription;
    messageSubscription:Subscription;
    constructor(private vehiculeService:VehiculeService,private eltRef:ElementRef,private http:HttpClient,private ngZone:NgZone,private formBuilder:FormBuilder) {
        jQuery(this.eltRef.nativeElement).ready(function() {

        });
    }


    ngOnInit() {

        this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data2:any) => {
            this.agences = data2.agences;
            console.log(this.agences);

        });

        this.errorSubscription=this.vehiculeService.errorSubject.subscribe((error)=>{
            this.error=error;
        });
        this.messageSubscription=this.vehiculeService.messageSubject.subscribe((message)=>{
            this.message=message;
        });
        setTimeout(()=>{
            this.message=null;
            this.error=null;
        },3000);


    }
    checkAll(event) {
        if (event.target.checked) {
            var checked=[];
            jQuery(".checkboxes").each(function () {
                checked.push(parseInt(jQuery(this).val()))

            });
            this.vehiculeschecked=checked;
        }
        if (!event.target.checked){
            this.vehiculeschecked=[];
        }
        console.log(this.vehiculeschecked);
    }
    onCheckboxChagen(event, value) {
        if (!event.target.checked) {
            let index = this.vehiculeschecked.indexOf(value);
            if (index > -1) {
                this.vehiculeschecked.splice(index, 1);
            }
        }
        if (event.target.checked) {
            this.vehiculeschecked.push(value);
        }

    }
    onDelete(){
        let tab=this.vehiculeschecked;
        if(confirm("Voulez vous vraiment Supprimer ces Vehicules")){
            for(let i=0;i<tab.length;i++){
                this.vehiculeService.delete(tab[i]);
            }
            this.refresh();
        }
    }

    onActivate(id,value){
        let status={hors_service:value};
        this.vehiculeService.editVehicule(status,id);
        this.refresh();
    }




    ngAfterViewInit(){
        this.http.get(`${environment["API_URL"]}`+'/vehicules').subscribe((data:any) => {
            this.vehicules = data.Vehicules;
            this.count=this.vehicules?this.vehicules.length:0;

            console.log(data);
            // waiting until select options are rendered
            jQuery("#sample_4").dataTable().fnDestroy();
            this.ngZone.onMicrotaskEmpty.subscribe(() => {
               // App.init();
                //FormWizard.init();
                //TableManaged.init();




                jQuery(this.eltRef.nativeElement).ready(function() {
                    jQuery.fn.dataTable.ext.errMode = 'none';

                    jQuery('#sample_4').on( 'error.dt', function ( e, settings, techNote, message ) {
                        console.log( 'An error has been reported by DataTables: ', message );
                    } ) ;
                });

            });
        });
    }
    refresh(){
        this.message=null;
        this.error=null;
        this.ngAfterViewInit();

    }
    private createDiv(node_name,textElement) {
        var _nodeElement = document.createElement(node_name);
        _nodeElement.innerHTML = textElement;
        return _nodeElement;
    }
    onFileSelected(event){
        let file: File = event.target.files[0];
        this.selectedfile=file;

    }



}
