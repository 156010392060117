import {EventEmitter, Injectable} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeChangePhoto=new EventEmitter();

  subsVar: Subscription;

    constructor() { }

    onChangePhoto(){
      this.invokeChangePhoto.emit();
    }

}
