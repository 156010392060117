import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';

declare var jQuery:any;
declare var Dropzone:any;


@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.css']
})
export class DropzoneComponent implements OnInit,AfterViewInit {
    @Input() url: string;
  constructor(private eltRef:ElementRef) { }

  ngOnInit() {
  }

    ngAfterViewInit(){
        jQuery(document).ready(function() {
            jQuery("#my-dropzone").dropzone();
        });
    }



}
