import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lieux-agence-add',
  templateUrl: './lieux-agence-add.component.html',
  styleUrls: ['./lieux-agence-add.component.css']
})
export class LieuxAgenceAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
