import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject} from "rxjs/internal/Subject";
import {environment} from "../../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class VignetteService {

    private vignettes:any[];
    private vignette:any;
    vignetteSubject=new Subject<any[]>();
    messageSubject =new Subject<any>();
    errorSubject =new Subject<any>();
    private message:string;
    private error:string;
    private url=`${environment["API_URL"]}`;
    constructor(private http:HttpClient) { }

    emitvignettes(){
        this.vignetteSubject.next(this.vignettes);
    }
    emitMessage(){
        this.messageSubject.next(this.message);
    }

    emitError(){
        this.errorSubject.next(this.error);
    }


    getvignettes(){
        this.http.get(this.url+'/vignettes').subscribe((data:any[])=>{
            this.vignettes=data;
            this.emitvignettes();
            console.log(this.vignettes);
        });
    }
    delete(id){
        this.http.delete(this.url+'/vignettes/delete/'+id).subscribe((data:any)=>{
            this.message=data;
            this.emitMessage();
        });
    }





    addvignette(vignette){
        this.http.post(`${environment["API_URL"]}`+'/vignettes/add',vignette).subscribe((data:any)=>
        {
            this.vignette= data;
            this.message=data.message;

            this.emitMessage();



        },error => {
            this.error=error;
            this.emitError();

        });

    }

    editvignettet(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/vignettes/updaten/'+id ,value,options).subscribe((data:any)=>{
            this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }
    editvignette(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/vignettes/update/'+id ,value,options).subscribe((data:any)=>{
            this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }
}
