import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../login/auth.service";
import { TiersService } from "../tiers.service";
declare var jQuery: any;

@Component({
  selector: 'app-tiers-add',
  templateUrl: './tiers-add.component.html',
  styleUrls: ['./tiers-add.component.css']
})
export class TiersAddComponent implements OnInit {
  isClient: boolean = true;
  isSte: boolean = false;
  isAssurance: boolean = false;
  isSteClient: boolean = true;
  isSteFour: boolean = false;
  user_id: number;
  agence_id: number;

  formTiers: FormGroup;

  constructor(private fb: FormBuilder, private authService: AuthService, private tiersService: TiersService) { }

  ngOnInit() {
    this.initForm();
    this.user_id = this.authService.AuthUser().id;
    this.agence_id = this.authService.AuthUser().agence_id;
  }

  selectClient() {
    this.isClient = true;
    this.isAssurance = false;
    this.isSte = false;
    this.initForm();
  }

  selectSte() {
    this.isClient = false;
    this.isAssurance = false;
    this.isSte = true;
    this.isSteClient = true;
    this.isSteFour = false;

    jQuery('#ste').click();
    this.initForm();
  }

  selectAssurance() { // Corrected method name to selectAssurance
    this.isClient = false;
    this.isAssurance = true;
    this.isSte = false;
    this.initForm();
  }

  initForm() {
    this.formTiers = this.fb.group({
      nom: ['', Validators.required], // Added Validators.required for mandatory fields
      prenom: [''],
      adresse_ma: '',
      adresse_etr: '',
      email: ['', Validators.email], // Added Validators.email for email validation
      tel1: '',
      tel2: '',
      ville: '',
      pays: '',
      cin: '',
      raison_social: '',
      identif_fiscal: '',
      agence_id: [''],
      nationalite: '',
      info_comp: '',
      sexe: '',
      code_postal: '',
      ice: '',
      patente: '',
      fix: '',
      fax: '',
      site_web: '',
      email_soc: '',
      secteur_activite: '',
      type_fourniture: '',
      compagnie_assurance: '',
      permis: '',
      date_valid_permis: '',
      remarques: '',
      isblocked: '',
      isclient: '',
      isassurance: '',
      isste: '',
      isfournisseur: '',
      user_id: '',
      blacklisted_id: '',
      date_naissance: ''
    });
  }

  selectSteCli() {
    this.isSteClient = !this.isSteClient;
  }

  selectSteFour() {
    this.isSteFour = !this.isSteFour;
  }

  onSubmitForm() {
    this.formTiers.value['user_id'] = this.user_id;
    this.formTiers.value['agence_id'] = this.agence_id;

    this.formTiers.value['isfournisseur'] = this.isSteFour && this.isSte ? 1 : 0;
    this.formTiers.value['isste'] = this.isSteClient && this.isSte ? 1 : 0;
    this.formTiers.value['isassurance'] = this.isAssurance ? 1 : 0;
    this.formTiers.value['isclient'] = this.isClient ? 1 : 0;
    this.formTiers.value['isblocked'] = 0;

    console.log(this.formTiers.value);
    this.tiersService.addtiers(this.formTiers.value); // Corrected method name to addtiers
  }
}
