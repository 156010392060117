import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../login/auth.service";
import {VehiculeService} from "../vehicule.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Brands} from "../brands";
import {HttpClient} from "@angular/common/http";
import {Subscription} from "rxjs/internal/Subscription";
import {User} from "../../models/user.model";
import {Observable} from "rxjs/internal/Observable";
import {utf8Encode} from "@angular/compiler/src/util";
import {DomSanitizer} from "@angular/platform-browser";
import {promise} from "selenium-webdriver";
import {environment} from "../../../environments/environment";
declare var jQuery:any;
@Component({
  selector: 'app-vehicule-show',
  templateUrl: './vehicule-show.component.html',
  styleUrls: ['./vehicule-show.component.css']
})
export class VehiculeShowComponent implements OnInit,AfterViewInit {

  constructor(private activRoute:ActivatedRoute,private _sanitizer: DomSanitizer,private ngZone:NgZone,private brands:Brands,private formBuilder:FormBuilder,private vehiculeService:VehiculeService,private router:Router,private eltRef:ElementRef,private http:HttpClient,private auth:AuthService) { }
    @Input() mark;
    @Input() lett;
    marques:any[];
    models:any[];
    agences:any[];
    vehiculeForm:FormGroup;
    vehiculeForm2:FormGroup;
    vehiculeForm3:FormGroup;

    vehiculeFormDoc:FormGroup;
    vehiculeDocs:any[];
    user:User;
    disable:boolean=true;
    activetab:boolean=false;
    message=null;
    idVehicule=null;
    error=null;
    submitedForm1:boolean;
    submitedForm3:boolean=false;
    displayDropz:boolean=false;
    editDoc:boolean=false;
    selectedDoc:any;
    imgVerso:any;
    imgRecto:any;
    tierss:any[];
    photoVehicules:any;
    vehicule:any;
    messageSubscription:Subscription;
    assuranceLoaded:Promise<boolean>;
    errorSubscription:Subscription;
    selectedfile1:File=null;
    selectedfile2:File=null;
    selectedfile3:File=null;
    selectedfile4:File=null;
    count:null;
    documentchecked:any[]=[];

    ngAfterViewInit(){

        this.getPhotoVehicules();
    }
  ngOnInit() {



       this.idVehicule=this.activRoute.snapshot.params['id'];

      this.initForm();
      this.initForm2();
      this.initFormdoc();
      this.initFormDocument();
      this.marques=this.brands.getBrands();
      this.user=this.auth.AuthUser();


      this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data2:any) => {
          this.agences = data2.agences;

      });
      this.http.get(`${environment["API_URL"]}`+'/vehicules/'+this.idVehicule).subscribe((data:any) => {
          this.vehicule = data.Vehicule;

      });
      this.http.get(`${environment["API_URL"]}`+'/vehicule/'+this.idVehicule+'/documents').subscribe((data:any) => {
          this.vehiculeDocs = data.DocumentVehicules;

      });




      this.errorSubscription=this.vehiculeService.errorSubject.subscribe((data)=>{
          this.error=data;
      });

      this.messageSubscription=this.vehiculeService.messageSubject.subscribe((data)=>{
          this.message=data;
      });
  this.selectedfile1=null;
  this.selectedfile2=null;
  this.selectedfile3=null;
  this.selectedfile4=null;

      this.getPhotoVehicules();

  }

  displayDrop(){
        this.displayDropz=true;
        console.log(this.displayDropz);
  }

    checkAll(event) {
        if (event.target.checked) {
            var checked=[];
            jQuery(".checkboxes").each(function () {
                checked.push(parseInt(jQuery(this).val()))

            });
            this.  documentchecked=checked;
        }
        if (!event.target.checked){
            this.  documentchecked=[];
        }
        console.log(this.  documentchecked);
    }
    onCheckboxChagen(event, value) {
        if (!event.target.checked) {
            let index = this.  documentchecked.indexOf(value);
            if (index > -1) {
                this.  documentchecked.splice(index, 1);
            }
        }
        if (event.target.checked) {
            this.  documentchecked.push(value);
        }

    }
    refresh(){
    this.ngOnInit();
    }

    initForm(){

        this.vehiculeForm=this.formBuilder.group({
            name:'',
            num_chassis:['',Validators.required],
            energie:['',Validators.required],
            prix_particulier_mo:['',Validators.required],
            prix_particulier_sem:['',Validators.required],

            boite_vitesse:['',[Validators.required]],
            marque:['',[Validators.required]],
            modele:['',[Validators.required]],
            portes:['',[Validators.max(7)]],
            passagers:['',[Validators.max(20)]] ,
            puissance_fiscal:['',Validators.required],
            climatise:'',
            couleur:['',[Validators.required]],
            serie_type:[''],
            user_id:'',
            agence_id:['',Validators.required],
            roue_secour:'',
            prix_particulier:['',Validators.required],
            prix_ste:['',Validators.required],
            num_cartegrise:['',Validators.required],
            info_comp:[''],
            remarque:[''],
            categorie:[''],
        });

    }

    initForm2(){

        this.vehiculeForm2=this.formBuilder.group({
            km_actuel:'',
            km_achat:[''],
            prix_achat:[''],
            prix_vente:[''],
            date_achat:[''],
            date_vente:['']
        });

    }
    initFormDocument(){
        this.vehiculeFormDoc=this.formBuilder.group({
            type:['',[Validators.required]],
            num_piece:['',[Validators.required]],
            date_obtention:[''],
            date_expiration:['',[Validators.required]],
            vehicule_id:[''],

        });


    }
    initFormdoc(){

        this.vehiculeForm3=this.formBuilder.group({
            type:'',
            num_piece:['',[Validators.required]],
            date_obtention:[''],
            date_expiration:['',[Validators.required]],
            vehicule_id:[''],

        })
        ;
    }

    disableNum(num){
        if(num==="WW"){
            this.disable=false;
        }
        else{
            this.disable=true;
        }
    }
    getModels(brand){
        this.models = this.marques.find(function(element) {
            return element.brand === brand;
        }).models;
        console.log();
    }

    NonNullForm(value) {
        const val = [value];

        if (val) {

            let filledProps = (val).map(el => {
                // Loop the property names of `el`, creating a new object
                // with the ones whose values aren't `null`.
                // `reduce` is commonly used for doing this:
                return Object.keys(el).reduce((newObj, key) => {
                    const value = el[key];
                    if (value !== "") {
                        newObj[key] = value;
                    }
                    return newObj;
                }, {});
            });
            return filledProps;
        }
        return null
    }

    onSubmitform2(){

        this.vehiculeService.editVehicule(this.NonNullForm(this.vehiculeForm2.value)[0],this.idVehicule);
        setTimeout(()=>{
            this.message=null;},1500);
    }
    onSubmitform(){

        this.vehiculeService.editVehicule(this.NonNullForm(this.vehiculeForm.value)[0],this.idVehicule);
        setTimeout(()=>{
            this.message=null;},1500);
    }


    onSubmitForm31(id){

        const value=this.vehiculeForm3.value;
        value['vehicule_id']=this.idVehicule;

            this.vehiculeService.editDoc(this.NonNullForm(value)[0],id,this.selectedfile1,this.selectedfile2);
        this.ngOnInit();
        this.ngOnInit();
        setTimeout(()=>{
            this.message=null;
            this.editDoc=false;
        },2000);


    }
    onSubmitFormDoc(){

        const value3=this.vehiculeFormDoc.value;
        value3['vehicule_id']=this.idVehicule;
        console.log(value3);
            this.vehiculeService.addDoc(value3,this.selectedfile3,this.selectedfile4);
            this.ngOnInit();
            this.ngOnInit();
            setTimeout(()=>{
              this.message=null;

            },2000);

    }
    cancelEdit(){
    this.editDoc=false;
    }



    onFileSelected(event){
        let file1: File = event.target.files[0];
        this.selectedfile1=file1;

    }

    onFileSelected2(event){
        let file1: File = event.target.files[0];
        this.selectedfile2=file1;

    }

    onFileSelected3(event){
        let file1: File = event.target.files[0];
        this.selectedfile3=file1;

    }

    onFileSelected4(event){
        let file1: File = event.target.files[0];
        this.selectedfile4=file1;

    }


    editDocSelected(id){
    this.editDoc=true;
        this.http.get(`${environment["API_URL"]}`+'/vehicule/document/'+id).subscribe((data:any) => {
          this.selectedDoc= data.DocumentVehicule;
        });
        this.getRecto(id);
        this.getVerso(id);

    }

    getVerso(id){
        this.getImage(' http://127.0.0.1:8000/api'+'/vehicule/document/verso/'+id).subscribe((data:any) => {
            this.createImageFromBlob2(data);

        },(error)=>{
            this.createImageFromBlob2(null);
            this.imgVerso=null;


        });

    }
    getRecto(id){
        this.getImage(' http://127.0.0.1:8000/api'+'/vehicule/document/recto/'+id).subscribe((data:any) => {
            this.createImageFromBlob(data);

        },(error)=>{
            this.createImageFromBlob(null);
            this.imgRecto=null;

        });

    }


    getImage(imageUrl: string): Observable<Blob> {
        return this.http.get(imageUrl, { responseType: 'blob' });
    }
    getImages(imageUrl: string): Observable<Blob> {
        return this.http.get(imageUrl, { responseType: 'blob' });
     }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.imgRecto = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }
    createImageFromBlob2(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.imgVerso = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    onDelete(id){
        let tab=this.documentchecked;
        if(confirm("Voulez vous vraiment Supprimer ces documents")){
            for(let i=0;i<tab.length;i++){
                this.vehiculeService.deleteDocument(tab[i]);
                this.documentchecked=[];
            }
            this.editDoc=false;
            this.ngOnInit();
            this.ngOnInit();
            setTimeout(()=>{
                this.message=null;

            },5000);
        }
    }

    createImagesFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.photoVehicules = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    getPhotoVehicules(){
        this.getImages(`${environment["API_URL"]}`+'/vehicule/'+this.idVehicule+'/photos').subscribe((data:any) => {
                this.photoVehicules=data;
                this.count=this.photoVehicules?this.photoVehicules.length:0;
                    jQuery(document).ready(function () {
                        //Portfolio.init();

                    });
        },(error)=>{
            this.createImagesFromBlob(null);
            this.imgRecto=null;

        });


    }

    deletePhoto(id){
        if(confirm("êtes-vous sûr de vouloir supprimer cette photo")){
        this.vehiculeService.deletePhoto(id);
       this.getPhotoVehicules();
       this.getPhotoVehicules();
            setTimeout(()=>{
                this.message=null;

            },2000);

        }
    }

    validateV(){
       this.ngOnInit();
       setTimeout( ()=>{jQuery('#go').click()},1000);
    }

}
