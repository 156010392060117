import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Agence} from "../../models/agence.model";
import {Subject} from "rxjs/internal/Subject";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AgenceService {
    message:string;
    error:String;
    messageSubject=new Subject<any>();
    errorSubject=new Subject<any>();
    agence:Agence;
    lieu:any;
    public agences:Agence[];
    agenceSubject=new Subject<Agence[]>();
    private url=`${environment["API_URL"]}`;

    constructor(private http:HttpClient) { }

    emitAgences(){
        this.agenceSubject.next(this.agences);
    }
    emitMessage(){
        this.messageSubject.next(this.message);

    }
    emitError(){
        this.errorSubject.next(this.error);
    }

    getAgences(){
        this.http.get(this.url+'/agences');
    }

    addAgence(agence){
        this.http.post(`${environment["API_URL"]}`+'/agences/add',agence).subscribe((data:any)=>
        {
            this.agence= data;
            this.message=data.message;

            this.emitMessage();



        },error => {
            this.error=error;
            this.emitError();

        });



    }
    editAgence(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/agences/update/'+id ,value,options).subscribe((data:any)=>{
           this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }

    addlieu(lieu){
        this.http.post(`${environment["API_URL"]}`+'/lieux/add',lieu).subscribe((data:any)=>
        {
            this.lieu= data;
            this.message=data.message;

            this.emitMessage();



        },error => {
            this.error=error;
            this.emitError();

        });



    }
    editlieu(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/lieux/update/'+id ,value,options).subscribe((data:any)=>{
           this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }

    delete(id){
        this.http.delete(this.url+'/lieus/delete/'+id).subscribe((data:any)=>{
            this.message=data.message;
            this.emitMessage();
        });
    }
 

}
