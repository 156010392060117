import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs/internal/Subscription";
import { HttpClient } from "@angular/common/http";
import { AgenceService } from "../agence.service";
import { Route, Router } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-agence-add',
  templateUrl: './agence-add.component.html',
  styleUrls: ['./agence-add.component.css']
})
export class AgenceAddComponent implements OnInit {

  agenceForm: FormGroup;
  selectedfile: File = null;
  message: any = null;
  error: any = null;
  responsable: any[] = [];
  userSubscription: Subscription;
  messageSubscription: Subscription;
  errorSubscription: Subscription;

  constructor(private agenceService: AgenceService, private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private _location: Location) { }

  ngOnInit() {
    this.initForm();
    this.messageSubscription = this.agenceService.messageSubject.subscribe((message) => {
      this.message = message;
    });
    this.errorSubscription = this.agenceService.errorSubject.subscribe((error) => {
      this.error = error;
    });

    this.getUsers();
  }

  initForm() {
    this.agenceForm = this.formBuilder.group({
      nom: ['', Validators.required],
      tel: ['', Validators.required],
      fax: [''],
      email: ['', [Validators.required, Validators.email]],
      ville: ['', Validators.required],
      pays: [''],
      responsable_id: ['', Validators.required],
      code_postal: [''],
      adresse: ['', Validators.required]
    });
  }

  getUsers() {
    this.http.get(`${environment["API_URL"]}` + '/users').subscribe((data: any) => {
      this.responsable = data.users; // Assign data.users directly to this.responsable
      console.log(data);
      console.log(this.responsable);
    });
  }

  onSubmitForm() {
    this.agenceService.addAgence(this.agenceForm.value);
    setTimeout(() => {
      this.message = null;
    }, 5000);
    this.router.navigate(['/agences']);
  }

  getback() {
    this._location.back();
  }

  refreshForm() {
    this.error = null;
    this.initForm();
  }
}
