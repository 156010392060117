// reservation.component.ts

import { Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { Vehicule } from '../models/vehicule.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ReservationService } from './reservation.service';
import { environment } from '../../environments/environment';

declare var jQuery: any;

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  count: number;
  reservationschecked: any[] = [];
  @Input() check: any;
  interests: any[];
  reservations: Vehicule[];
  roles: any[];
  agences: any[];
  selectedfile: File;
  formVehicule: FormGroup;
  message: null;
  error: null;

  errorSubscription: Subscription;
  messageSubscription: Subscription;
  
  constructor(private reservationservice: ReservationService, private eltRef: ElementRef, private http: HttpClient, private ngZone: NgZone, private formBuilder: FormBuilder) {
    jQuery(this.eltRef.nativeElement).ready(function () {

    });
  }

  ngOnInit() {
    this.errorSubscription = this.reservationservice.errorSubject.subscribe((error) => {
      this.error = error;
    });
    this.messageSubscription = this.reservationservice.messageSubject.subscribe((message) => {
      this.message = message;
    });
    setTimeout(() => {
      this.message = null;
      this.error = null;
    }, 3000);
  }

  checkAll(event) {
    if (event.target.checked) {
      var checked = [];
      jQuery(".checkboxes").each(function () {
        checked.push(parseInt(jQuery(this).val()))
      });
      this.reservationschecked = checked;
    }
    if (!event.target.checked) {
      this.reservationschecked = [];
    }
    console.log(this.reservationschecked);
  }

  onCheckboxChange(event, value) {
    if (!event.target.checked) {
      let index = this.reservationschecked.indexOf(value);
      if (index > -1) {
        this.reservationschecked.splice(index, 1);
      }
    }
    if (event.target.checked) {
      this.reservationschecked.push(value);
    }
  }

  onDelete() {
    let tab = this.reservationschecked;
    if (confirm("Voulez-vous vraiment supprimer ces réservations ?")) {
      for (let i = 0; i < tab.length; i++) {
        this.reservationservice.delete(tab[i]);
      }
      this.refresh();
    }
  }

  onActivate(id, value) {
    let status = { hors_service: value };
    this.reservationservice.editReservation(status, id); // Replace editVehicule with editReservation
    this.refresh();
  }
  

  ngAfterViewInit() {
    this.http.get(`${environment.API_URL}/reservations`).subscribe((data: any) => {
      this.reservations = data.Reservations;
      this.count = this.reservations ? this.reservations.length : 0;

      console.log(data);
      // waiting until select options are rendered
      jQuery("#sample_16").dataTable().fnDestroy();
      this.ngZone.onMicrotaskEmpty.subscribe(() => {
        // App.init();
        //FormWizard.init();
        //TableManaged.init();

        jQuery(this.eltRef.nativeElement).ready(function () {
          jQuery.fn.dataTable.ext.errMode = 'none';

          jQuery('#sample_16').on('error.dt', function (e, settings, techNote, message) {
            console.log('An error has been reported by DataTables: ', message);
          });
        });

      });
    });
  }

  refresh() {
    this.message = null;
    this.error = null;
    this.ngAfterViewInit();
  }

  private createDiv(node_name, textElement) {
    var _nodeElement = document.createElement(node_name);
    _nodeElement.innerHTML = textElement;
    return _nodeElement;
  }

  onFileSelected(event) {
    let file: File = event.target.files[0];
    this.selectedfile = file;
  }

  dateDiff(date1, date2) {
    const a = new Date(date1);
    const b = new Date(date2);
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY) + ' jours ' + Math.floor(((utc2 - utc1) % _MS_PER_DAY) / 24) + 'h';
  }
}
