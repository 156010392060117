import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject} from "rxjs/internal/Subject";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ReparationService {
    private reparations:any[];
    private reparation:any;
    reparationSubject=new Subject<any[]>();
    messageSubject =new Subject<any>();
    errorSubject =new Subject<any>();
    private message:string;
    private error:string;
    private url=`${environment["API_URL"]}`;
    constructor(private http:HttpClient) { }


    emitreparations(){
        this.reparationSubject.next(this.reparations);
    }
    emitMessage(){
        this.messageSubject.next(this.message);
    }

    emitError(){
        this.errorSubject.next(this.error);
    }


    getreparations(){
        this.http.get(this.url+'/reparations').subscribe((data:any[])=>{
            this.reparations=data;
            this.emitreparations();
            console.log(this.reparations);
        });
    }
    delete(id){
        this.http.delete(this.url+'/reparations/delete/'+id).subscribe((data:any)=>{
            this.message=data;
            this.emitMessage();
        });
    }





    addreparation(reparation){
        this.http.post(`${environment["API_URL"]}`+'/reparations/add',reparation).subscribe((data:any)=>
        {
            this.reparation= data;
            this.message=data.message;

            this.emitMessage();



        },error => {
            this.error=error;
            this.emitError();

        });

    }

    editreparationt(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/reparations/updaten/'+id ,value,options).subscribe((data:any)=>{
            this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }
    editreparation(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/reparations/update/'+id ,value,options).subscribe((data:any)=>{
            this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }
}
