import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {VidangeService} from "../vidange.service";
import {AuthService} from "../../../login/auth.service";
import {environment} from "../../../../environments/environment";
declare var jQuery:any;
@Component({
    selector: 'app-vidange-add',
    templateUrl: './vidange-add.component.html',
    styleUrls: ['./vidange-add.component.css']
})
export class VidangeAddComponent implements OnInit,OnChanges {

    @Input() vehicule_id: number;
    @Input() agence_id: number;
    @Input() vidange_id: number;
    @Output() vidangeChange: EventEmitter<any> = new EventEmitter();
    vidangeForm: FormGroup;
    
    message=null;
    error=null;
    user:any;
    vidange:any;
    vehicules:any[];
    agences:any[];
    currentpage:boolean;

    messageSubscription:Subscription;
    errorSubscription:Subscription;
    constructor(private vidangeService:VidangeService,private auth:AuthService,private formBuilder:FormBuilder,private http:HttpClient,private router:Router,private _location:Location) { }

    ngOnInit() {
        this.vidangeChange.emit(this.vidange_id);
        this.currentpage=this.router.url==='/vehicules/addvidange';
        this.initForm();
        this.formModif();
        this.messageSubscription=this.vidangeService.messageSubject.subscribe((message)=>{
            this.message=message;

        });

        this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data:any) => {
            this.agences = data.agences;
        });

        this.errorSubscription=this.vidangeService.errorSubject.subscribe((error)=>{

            this.error=error;
        });

        this.user=this.auth.AuthUser();



    }

    initForm(){
        this.message=null;
        this.vidangeForm=this.formBuilder.group({
            km_actuel:['',Validators.required],
            km_prochain:['',Validators.required],
            date_vidange:['',Validators.required],
            date_paiment:[''],
            montant:[''],
            mode_reglement:[''],
            num_cheque:[''],
            banque:[''],
            commentaire:[''],
            vehicule_id:['',!this.vehicule_id && !this.vidange_id?[Validators.required]:[]],
            user_id:[''],

            agence_id:[''],
        })
    }

    formModif(){
        if(this.vidange_id){
            this.http.get(`${environment["API_URL"]}`+'/vidanges/'+this.vidange_id).subscribe((data:any) => {
                this.vidange = data;
            });
        }

    }

    changeAgence(id){
        this.vehicules=this.agences.find((agence)=>{
            return agence.agence.id === +id;
        }).vehicules;

    }

    onSubmitForm(){
        if(this.vehicule_id && !this.vidange){
            this.vidangeForm.value['vehicule_id']=this.vehicule_id;

        }
        this.vidangeForm.value['user_id']=this.user.id;

        this.vidangeService.addvidange(this.vidangeForm.value);

        setTimeout(()=>{
            this.message=null;
            this.message=null;

        },5000);
        if(!this.vehicule_id){
            this.router.navigate(['/vehicules/vidanges']);
            jQuery('#wide2').modal('toggle');
        }
        if(this.vehicule_id){
            jQuery('#wide2').modal('toggle');
        }

        this.initForm();


    }

    getback(){
        this._location.back();
    }

    refreshForm(){
        this.error=null;
        this.initForm();
    }

    ngOnChanges(){


        if(this.vidange_id){
            this.http.get(`${environment["API_URL"]}`+'/vidanges/'+this.vidange_id).subscribe((data:any) => {
                this.vidange = data;
            });
        }

    }

    NonNullForm(value) {
        const val = [value];

        if (val) {

            let filledProps = (val).map(el => {
                // Loop the property names of `el`, creating a new object
                // with the ones whose values aren't `null`.
                // `reduce` is commonly used for doing this:
                return Object.keys(el).reduce((newObj, key) => {
                    const value = el[key];
                    if (value !== "") {
                        newObj[key] = value;
                    }
                    return newObj;
                }, {});
            });
            return filledProps;
        }
        return null
    }


    onSubmitFormEdit(){
        const value=this.NonNullForm(this.vidangeForm.value)[0];
        this.vidangeService.editvidange(value,this.vidange_id);
        this.initForm();
        setTimeout(()=>{
            this.message=null;
            this.formModif();
        },2000)

    }

    annulerPaiment(){
        this.vidangeForm.value['montant']=null;
        this.vidangeForm.value['date_paiment']=null;
        this.vidangeForm.value['banque']=null;
        this.vidangeForm.value['num_cheque']=null;
        this.vidangeForm.value['mode_reglement']=null;


        this.vidangeService.editvidanget(this.vidangeForm.value,this.vidange_id);
        this.initForm();
        setTimeout(()=>{
            this.message=null;
            this.formModif();
        },2000)

    }




}
