import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
declare var jQuery:any;
@Component({
  selector: 'app-lieux-agence',
  templateUrl: './lieux-agence.component.html',
  styleUrls: ['./lieux-agence.component.css']
})
export class LieuxAgenceComponent implements OnInit,AfterViewInit {

  lieus:any[];
  count:any;
  agences:any;
  @Input() id_agence;
  formEdit:FormGroup;
  constructor(private http:HttpClient ,private ngZone:NgZone,private eltRef:ElementRef,private formBuilder:FormBuilder) { }

  ngOnInit() {
console.log(this.lieus);
  }
  ngAfterViewInit(){
      this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data:any) => {
          this.agences = data.agences;});
      if(this.id_agence){
          this.http.get(`${environment["API_URL"]}`+'/lieux/'+this.id_agence).subscribe((data:any) => {
              this.lieus = data.lieus;
              this.count=this.lieus?this.lieus.length:0;

              console.log(data);
              // waiting until select options are rendered
              jQuery("#sample_editable_1").dataTable().fnDestroy();
              this.ngZone.onMicrotaskEmpty.subscribe(() => {
                  // App.init();

                  //TableEditable1.init();

                  jQuery(this.eltRef.nativeElement).ready(function() {
                      jQuery.fn.dataTable.ext.errMode = 'none';
                      jQuery('#sample_editable_1').on( 'error.dt', function ( e, settings, techNote, message ) {
                          console.log( 'An error has been reported by DataTables: ', message );
                      } ) ;
                  });

              });
          });

      }

      if(!this.id_agence){
      this.http.get(`${environment["API_URL"]}`+'/lieux').subscribe((data:any) => {
          this.lieus = data.lieus;
          this.count=this.lieus?this.lieus.length:0;

          console.log(data);
          // waiting until select options are rendered
          jQuery("#sample_editable_1").dataTable().fnDestroy();
          this.ngZone.onMicrotaskEmpty.subscribe(() => {
              // App.init();

              //TableEditable1.init();

              jQuery(this.eltRef.nativeElement).ready(function() {
                  jQuery.fn.dataTable.ext.errMode = 'none';
                  jQuery('#sample_editable_1').on( 'error.dt', function ( e, settings, techNote, message ) {
                      console.log( 'An error has been reported by DataTables: ', message );
                  } ) ;
              });

          });
      });
      }
  }

    addlieu(){

    }
    initForm(){
        this.formEdit=this.formBuilder.group({
            lieu:['',Validators.required],
            prix_nuit:[''],
            prix_jour:[''],
            agence_id:[''],

        })
    }
    editlieu(id){
        console.log(id);
    }

}
