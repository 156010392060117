import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject} from "rxjs/internal/Subject";
import {environment} from "../../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class PieceConsommableService {

    private pieces:any[];
    private piece:any;
    pieceSubject=new Subject<any[]>();
    messageSubject =new Subject<any>();
    errorSubject =new Subject<any>();
    private message:string;
    private error:string;
    private url=`${environment["API_URL"]}`;
    constructor(private http:HttpClient) { }

    emitpieces(){
        this.pieceSubject.next(this.pieces);
    }
    emitMessage(){
        this.messageSubject.next(this.message);
    }

    emitError(){
        this.errorSubject.next(this.error);
    }


    getpieces(){
        this.http.get(this.url+'/pieces').subscribe((data:any[])=>{
            this.pieces=data;
            this.emitpieces();
            console.log(this.pieces);
        });
    }
    delete(id){
        this.http.delete(this.url+'/pieces/delete/'+id).subscribe((data:any)=>{
            this.message=data;
            this.emitMessage();
        });
    }





    addpiece(piece){
        this.http.post(`${environment["API_URL"]}`+'/pieces/add',piece).subscribe((data:any)=>
        {
            this.piece= data;
            this.message=data.message;

            this.emitMessage();



        },error => {
            this.error=error;
            this.emitError();

        });

    }

    editpiecet(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/pieces/updaten/'+id ,value,options).subscribe((data:any)=>{
            this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }
    editpiece(value,id){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let options = { headers: headers };
        this.http.put(`${environment["API_URL"]}`+'/pieces/update/'+id ,value,options).subscribe((data:any)=>{
            this.message=data.message;
            this.emitMessage();
        },(error)=>{
            this.error=error;
            console.log(value);
            console.log(error);
            this.emitError();
        })
    }
}
