import {Component, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";
import {ReparationService} from "./reparation.service";
import {FormBuilder} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";



declare var jQuery:any;
@Component({
    selector: 'app-reparation',
    templateUrl: './reparation.component.html',
    styleUrls: ['./reparation.component.css']
})
export class ReparationComponent implements OnInit {

    reparationschecked:any[]=[];
    @Input() check:any;
    @Input() vehicule_id:number;
    @Input() user_id:number;
    interests:any[];
    reparations:any[];
    agence_id:number;
    agence:any;
    vehicule:any;
    editAss:boolean=false;
    reparationsLoaded: Promise<boolean>;
    count:number=0;
    message:null;
    error:null;
    reparation_id:null;
    vehicul_id:null;
    agenc_id:null;

    errorSubscription:Subscription;
    messageSubscription:Subscription;
    constructor(private reparationService:ReparationService,private eltRef:ElementRef,private http:HttpClient,private ngZone:NgZone,private formBuilder:FormBuilder) {

    }


    ngOnInit() {

        this.errorSubscription=this.reparationService.errorSubject.subscribe((error)=>{
            this.error=error;
        });
        this.messageSubscription=this.reparationService.messageSubject.subscribe((message)=>{
            this.message=message;
            if(this.message){
                setTimeout(()=>{this.ngOnInit()},1000);
            }
        });
        setTimeout(()=>{
            this.message=null;
            this.error=null;
        },3000);
        this.getreparations();
        this.reparationschecked=[];

    }

    getreparations(){
        if(this.vehicule_id){
            this.http.get(`${environment["API_URL"]}`+'/vehicules/'+this.vehicule_id).subscribe((data:any) => {
                this.reparations = data.reparations;
                this.agence=data.agence;
                this.vehicule=data.Vehicule;
                this.count=this.reparations? this.reparations.length :0;
                this.agence_id=this.agence.id;
                console.log(this.reparations);
                console.log(this.agence);
                console.log(this.vehicule);
                jQuery("#sample_8").dataTable().fnDestroy();
                jQuery("#sample_6").dataTable().fnDestroy();
                jQuery("#sample_7").dataTable().fnDestroy();
                jQuery("#sample_9").dataTable().fnDestroy();

                this.ngZone.onMicrotaskEmpty.subscribe(() => {
                    // App.init();
                    //FormWizard.init();
                    //TableManaged.init();

                    jQuery(this.eltRef.nativeElement).ready(function() {
                        jQuery.fn.dataTable.ext.errMode = 'none';

                        jQuery('#sample_9').on( 'error.dt', function ( e, settings, techNote, message ) {
                            console.log( 'An error has been reported by DataTables: ', message );
                        } ) ;
                    });

                });


            });}
        else{
            this.http.get(`${environment["API_URL"]}`+'/reparations').subscribe((data:any) => {

                    this.reparations = data.Reparations;
                    jQuery("#sample_9").dataTable().fnDestroy();


                    this.ngZone.onMicrotaskEmpty.subscribe(() => {
                        // App.init();
                        //FormWizard.init();
                        //TableManaged.init();

                        jQuery(this.eltRef.nativeElement).ready(function() {
                            jQuery.fn.dataTable.ext.errMode = 'none';

                            jQuery('#sample_9').on( 'error.dt', function ( e, settings, techNote, message ) {
                                console.log( 'An error has been reported by DataTables: ', message );
                            } ) ;
                        });

                    });

                    this.count=this.reparations? this.reparations.length :0;

                    // waiting until select options are rendered

                },
                ()=>{
                    this.reparations=null;
                });
        }



    }
    checkAll(event) {
        if (event.target.checked) {
            var checked=[];
            jQuery(".checkboxes").each(function () {
                checked.push(parseInt(jQuery(this).val()))

            });
            this.reparationschecked=checked;
        }
        if (!event.target.checked){
            this.reparationschecked=[];
        }
        console.log(this.reparationschecked);
    }
    onCheckboxChagen(event, value) {

        if (!event.target.checked) {
            let index = this.reparationschecked.indexOf(value);
            if (index > -1) {
                this.reparationschecked.splice(index, 1);
            }
        }
        if (event.target.checked) {
            this.reparationschecked.push(value);
        }

    }
    onDelete(){
        let tab=this.reparationschecked;
        if(confirm("Voulez vous vraiment Supprimer ces reparations")){
            for(let i=0;i<tab.length;i++){
                this.reparationService.delete(tab[i]);
            }
            this.editAss=false;
            this.refresh();
        }
    }

    onActivate(id,value){
        let status={disabled:value};
        this.reparationService.editreparation(status,id);
        this.refresh();
    }




    ngAfterViewInit(){


    }

    refresh(){
        this.message=null;
        this.error=null;
        this.ngOnInit();

    }

    editass(idass){

        this.reparation_id=idass;
        this.editAss=true;
        if( document.getElementById('paiment'))
            document.getElementById('paiment').scrollIntoView({behavior: "smooth"});

    }

    changereparation(data){
        this.reparation_id=data;
    }


}
