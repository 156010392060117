import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VehiculeService {
  private vehicules: any[];
  private vehicule: any;
  private doc: any;
  vehiculeSubject = new Subject<any[]>();
  messageSubject = new Subject<any>();
  idSubject = new Subject<any>();
  errorSubject = new Subject<any>();
  private message: string;
  private idVehicule: number;
  private error: string;
  private url = `${environment.API_URL}`;

  constructor(private http: HttpClient, private router: Router) { }

  emitVehicules() {
    this.vehiculeSubject.next(this.vehicules);
  }

  emitMessage() {
    this.messageSubject.next(this.message);
  }

  emitId() {
    this.idSubject.next(this.idVehicule);
  }

  emitError() {
    this.errorSubject.next(this.error);
  }

  getVehicules() {
    this.http.get<any[]>(`${this.url}/vehicules`).subscribe((data) => {
      this.vehicules = data;
      this.emitVehicules();
      console.log(this.vehicules);
    });
  }

  delete(id: number) {
    this.http.delete(`${this.url}/vehicules/delete/${id}`).subscribe((data: any) => {
      this.message = data;
      this.emitMessage();
    });
  }

  deleteDocument(id: number) {
    this.http.delete(`${this.url}/vehicule/document/delete/${id}`).subscribe((data: any) => {
      this.message = data;
      this.emitMessage();
    });
  }

  deletePhoto(id: number) {
    this.http.delete(`${this.url}/vehicule/photos/delete/${id}`).subscribe((data: any) => {
      this.message = data;
      this.emitMessage();
    });
  }

  changePhoto(file: File, id: number) {
    if (file) {
      const formData: FormData = new FormData();
      formData.append('photo', file, file.name);

      const headers = new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.token
      });

      this.http.post(`${this.url}/Vehicules/updateImage/${id}`, formData, { headers }).subscribe(
        data => {
          console.log('success');
        },
        error => console.log(error)
      );
    }
  }

  removePhoto(id: number) {
    this.http.get(`${this.url}/Vehicules/removeImage/${id}`).subscribe(
      (data: any) => {
        this.message = data.message;
        this.emitMessage();
        console.log(data);
      },
      error => console.log(error)
    );
  }

  addDoc(value: any, img_recto: File, img_verso: File) {
    this.http.post(`${this.url}/vehicule/document/add`, value).subscribe((data: any) => {
      this.doc = data;
      this.message = data.message;
      this.emitMessage();

      if (data) {
        const id = data.document.id;
        if (img_recto) {
          const formData: FormData = new FormData();
          formData.append('image_recto', img_recto, img_recto.name);

          const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.token
          });

          this.http.post(`${this.url}/vehicule/document/recto/${id}`, formData, { headers }).subscribe(
            data => {
              console.log('success');
            },
            error => console.log(error)
          );
        }

        if (img_verso) {
          const formData2: FormData = new FormData();
          formData2.append('image_verso', img_verso, img_verso.name);

          const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.token
          });

          this.http.post(`${this.url}/vehicule/document/verso/${id}`, formData2, { headers }).subscribe(
            data => {
              console.log('success');
            },
            error => console.log(error)
          );
        }
      }
    }, error => {
      this.error = error;
      this.emitError();
    });
  }

  addVehicule(vehicule: any) {
    this.http.post<any>(`${this.url}/vehicules/add`, vehicule).subscribe((data: any) => {
      this.idVehicule = data.vehicule_id;
      this.message = data.message;
      this.emitId();
      this.emitMessage();
    }, error => {
      this.error = error.error;
      this.emitError();
    });
  }

  editVehicule(value: any, id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this.http.put<any>(`${this.url}/vehicules/update/${id}`, value, { headers }).subscribe((data: any) => {
      console.log(data);
      this.message = data.message;
      this.emitMessage();
    }, (error) => {
      this.error = error;
      console.log(value);
      console.log(error);
      this.emitError();
    });
  }

  editDoc(value: any, id: number, img_recto: File, img_verso: File) {
    this.http.put<any>(`${this.url}/vehicule/document/update/${id}`, value).subscribe((data: any) => {
      this.doc = data;
      this.message = data.message;
      this.emitMessage();

      if (data) {
        if (img_recto) {
        
          const formData: FormData = new FormData();
          formData.append('image_recto', img_recto, img_recto.name);

          const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.token
          });

          this.http.post<any>(`${this.url}/vehicule/document/recto/${data.document.id}`, formData, { headers }).subscribe(
            data => {
              console.log('success');
            },
            error => console.log(error)
          );
        }

        if (img_verso) {
          const formData2: FormData = new FormData();
          formData2.append('image_verso', img_verso, img_verso.name);

          const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.token
          });

          this.http.post<any>(`${this.url}/vehicule/document/verso/${data.document.id}`, formData2, { headers }).subscribe(
            data => {
              console.log('success');
            },
            error => console.log(error)
          );
        }
      }

    }, error => {
      this.error = error;
      this.emitError();
    });

  }
}
