import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { LoginComponent } from './login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {AuthService} from "./login/auth.service";
import {TokenService} from "./login/token.service";
import {AuthGuardService} from "./services/auth-guard.service";
import {AuthGuardBeforeService} from "./services/auth-guard-before.service";
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { UserComponent } from './user/user.component';
import {UserService} from "./user/user.service";
import { AgenceComponent } from './user/agence/agence.component';
import {AgenceService} from "./user/agence/agence.service";
import { AgenceShowComponent } from './user/agence/agence-show/agence-show.component';
import { AdministrationComponent } from './administration/administration.component';
import {AdministrationService} from "./administration/administration.service";
import {UserShowComponent} from "./user/user-show/user-show.component";
import {MenuService} from "./home/menu.service";
import { VehiculeComponent } from './vehicule/vehicule.component';
import {VehiculeService} from "./vehicule/vehicule.service";
import { VehiculeAddComponent } from './vehicule/vehicule-add/vehicule-add.component';
import { VehiculeShowComponent } from './vehicule/vehicule-show/vehicule-show.component';
import { UserAddComponent } from './user/user-add/user-add.component';
import { AgenceAddComponent } from './user/agence/agence-add/agence-add.component';
import {Brands} from "./vehicule/brands";
import { DropzoneComponent } from './dropzone/dropzone.component';
import { AssuranceComponent } from './vehicule/assurance/assurance.component';
import { VignetteComponent } from './vehicule/vignette/vignette.component';
import { VisiteTechniqueComponent } from './vehicule/visite-technique/visite-technique.component';
import { VidangeComponent } from './vehicule/vidange/vidange.component';
import { PieceConsommableComponent } from './vehicule/piece-consommable/piece-consommable.component';
import { ReparationComponent } from './vehicule/reparation/reparation.component';
import {AssuranceService} from "./vehicule/assurance/assurance.service";
import { AssuranceAddComponent } from './vehicule/assurance/assurance-add/assurance-add.component';
import { VignetteAddComponent } from './vehicule/vignette/vignette-add/vignette-add.component';
import { VisiteAddComponent } from './vehicule/visite-technique/visite-add/visite-add.component';
import { VidangeAddComponent } from './vehicule/vidange/vidange-add/vidange-add.component';
import {VidangeService} from "./vehicule/vidange/vidange.service";
import { ReparationAddComponent } from './vehicule/reparation/reparation-add/reparation-add.component';
import { PieceConsommableAddComponent } from './vehicule/piece-consommable/piece-consommable-add/piece-consommable-add.component';
import {ReparationService} from "./vehicule/reparation/reparation.service";
import {PieceConsommableService} from "./vehicule/piece-consommable/piece-consommable.service";
import {VisiteService} from "./vehicule/visite-technique/visite.service";
import {VignetteService} from "./vehicule/vignette/vignette.service";
import {EventEmitterService} from "./event-emitter.service";
import { TiersComponent } from './tiers/tiers.component';
import { TiersAddComponent } from './tiers/tiers-add/tiers-add.component';
import { TiersShowComponent } from './tiers/tiers-show/tiers-show.component';
import { DocumentComponent } from './tiers/document/document.component';
import { DocumentAddComponent } from './tiers/document/document-add/document-add.component';
import { DocumentShowComponent } from './tiers/document/document-show/document-show.component';
import { BlackListComponent } from './tiers/black-list/black-list.component';
import {TiersService} from "./tiers/tiers.service";
import {DocumentService} from "./tiers/document/document.service";
import { ReservationComponent } from './reservation/reservation.component';
import {ReservationService} from "./reservation/reservation.service";
import { ReservationAddComponent } from './reservation/reservation-add/reservation-add.component';
import { LieuxAgenceComponent } from './user/agence/lieux-agence/lieux-agence.component';
import { LieuxAgenceAddComponent } from './user/agence/lieux-agence-add/lieux-agence-add.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    TopNavbarComponent,
    LoginComponent,
    ChangePasswordComponent,
    UserComponent,
    AgenceComponent,
    AgenceShowComponent,
    AdministrationComponent,
      UserShowComponent,
      VehiculeComponent,
      VehiculeAddComponent,
      VehiculeShowComponent,
      UserAddComponent,
      AgenceAddComponent,
      DropzoneComponent,
      AssuranceComponent,
      VignetteComponent,
      VisiteTechniqueComponent,
      VidangeComponent,
      PieceConsommableComponent,
      ReparationComponent,
      AssuranceAddComponent,
      VignetteAddComponent,
      VisiteAddComponent,
      VidangeAddComponent,
      ReparationAddComponent,
      PieceConsommableAddComponent,
      TiersComponent,
      TiersAddComponent,
      TiersShowComponent,
      DocumentComponent,
      DocumentAddComponent,
      DocumentShowComponent,
      BlackListComponent,
      ReservationComponent,
      ReservationAddComponent,
      LieuxAgenceComponent,
      LieuxAgenceAddComponent



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,

  ],
  providers: [AuthService,TokenService,AuthGuardService,AuthGuardBeforeService,UserService,AgenceService,AdministrationService,MenuService,VehiculeService,Brands,AssuranceService,
      VidangeService,ReparationService,PieceConsommableService,VisiteService,VignetteService,EventEmitterService,TiersService,DocumentService,ReservationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
