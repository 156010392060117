import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {PieceConsommableService} from "../piece-consommable.service";
import {AuthService} from "../../../login/auth.service";
import {environment} from "../../../../environments/environment";
declare var jQuery:any;
@Component({
    selector: 'app-piece-consommable-add',
    templateUrl: './piece-consommable-add.component.html',
    styleUrls: ['./piece-consommable-add.component.css']
})
export class PieceConsommableAddComponent implements OnInit,OnChanges {

    pieceForm: FormGroup;
    @Input() vehicule_id: number;
    @Input() agence_id: number;
    @Input() piece_id: number;
    @Output() pieceChange: EventEmitter<any> = new EventEmitter<any>();
    message=null;
    error=null;
    user:any;
    piece:any;
    vehicules:any[];
    agences:any[];
    currentpage:boolean;

    messageSubscription:Subscription;
    errorSubscription:Subscription;
    constructor(private pieceService:PieceConsommableService,private auth:AuthService,private formBuilder:FormBuilder,private http:HttpClient,private router:Router,private _location:Location) { }

    ngOnInit() {
        this.pieceChange.emit(this.piece_id);
        this.currentpage=this.router.url==='/vehicules/addpiececonsommable';
        this.initForm();
        this.formModif();
        this.messageSubscription=this.pieceService.messageSubject.subscribe((message)=>{
            this.message=message;

        });

        this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data:any) => {
            this.agences = data.agences;
        });

        this.errorSubscription=this.pieceService.errorSubject.subscribe((error)=>{

            this.error=error;
        });

        this.user=this.auth.AuthUser();



    }

    initForm(){
        this.message=null;
        this.pieceForm=this.formBuilder.group({
            vendeur:['',Validators.required],
            piece:['',Validators.required],
            date_piece:['',Validators.required],
            date_paiment:[''],
            prix_unite:['',Validators.required],
            qte:['',Validators.required],
            montant:[''],
            mode_reglement:[''],
            num_cheque:[''],
            banque:[''],
            commentaire:[''],
            vehicule_id:['',!this.vehicule_id && !this.piece_id?[Validators.required]:[]],
            user_id:[''],

            agence_id:['',!this.vehicule_id && !this.piece_id?[Validators.required]:[]],
        })
    }

    formModif(){
        if(this.piece_id){
            this.http.get(`${environment["API_URL"]}`+'/pieces/'+this.piece_id).subscribe((data:any) => {
                this.piece = data;
            });
        }

    }

    changeAgence(id){
        this.vehicules=this.agences.find((agence)=>{
            return agence.agence.id === +id;
        }).vehicules;

    }

    onSubmitForm(){
        if(this.vehicule_id && !this.piece){
            this.pieceForm.value['vehicule_id']=this.vehicule_id;

        }
        this.pieceForm.value['user_id']=this.user.id;

        this.pieceService.addpiece(this.pieceForm.value);

        setTimeout(()=>{
            this.message=null;
            this.message=null;

        },5000);
        if(!this.vehicule_id){
            this.router.navigate(['/vehicules/piecesconsommable']);
            jQuery('#wide4').modal('toggle');
        }
        if(this.vehicule_id){
            jQuery('#wide4').modal('toggle');
        }

        this.initForm();
        this.initForm();


    }

    getback(){
        this._location.back();
    }

    refreshForm(){
        this.error=null;
        this.initForm();
    }

    ngOnChanges(){


        if(this.piece_id){
            this.http.get(`${environment["API_URL"]}`+'/pieces/'+this.piece_id).subscribe((data:any) => {
                this.piece = data;
            });
        }

    }

    NonNullForm(value) {
        const val = [value];

        if (val) {

            let filledProps = (val).map(el => {
                // Loop the property names of `el`, creating a new object
                // with the ones whose values aren't `null`.
                // `reduce` is commonly used for doing this:
                return Object.keys(el).reduce((newObj, key) => {
                    const value = el[key];
                    if (value !== "") {
                        newObj[key] = value;
                    }
                    return newObj;
                }, {});
            });
            return filledProps;
        }
        return null
    }


    onSubmitFormEdit(){
        const value=this.NonNullForm(this.pieceForm.value)[0];
        this.pieceService.editpiece(value,this.piece_id);
        this.initForm();
        setTimeout(()=>{
            this.message=null;
            this.formModif();
        },2000)

    }

    annulerPaiment(){
        this.pieceForm.value['montant']=null;
        this.pieceForm.value['date_paiment']=null;
        this.pieceForm.value['banque']=null;
        this.pieceForm.value['num_cheque']=null;
        this.pieceForm.value['mode_reglement']=null;


        this.pieceService.editpiecet(this.pieceForm.value,this.piece_id);
        this.initForm();
        setTimeout(()=>{
            this.message=null;
            this.formModif();
        },2000)

    }




}
