import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "./auth.service";
import {User} from "../models/user.model";
import {Subscription} from "rxjs/internal/Subscription";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css','../../assets/plugins/select2/select2.css']
})
export class LoginComponent implements OnInit,AfterViewInit {

    ngAfterViewInit(){
       return new Promise(resolve => {

            let  str_Elemenr = `

jQuery(document).ready(function() {     
  //App.init();
  Login.init();
  var action = location.hash.substr(1);
          if (action == 'createaccount') {
              
              $('.login-form').hide();
              $('.forget-form').hide();
          } else if (action == 'forgetpassword')  {
              
              $('.login-form').hide();
              $('.forget-form').show();
          }
});

    `;
            let nodeElement =   this.createDiv("script",str_Elemenr);
            nodeElement.onload=resolve;
            nodeElement.onload=resolve;
            document.body.appendChild(nodeElement);
        });


    }


    private createDiv(node_name,textElement) {
        var _nodeElement = document.createElement(node_name);
        _nodeElement.innerHTML = textElement;
        return _nodeElement;
    }
    private createScript(src) {
        let sc=document.createElement("script",);
        sc.type="text/javascript";
        sc.src=src;
        return sc;
    }



  errors:any;
  errorSubscription:Subscription;
  messages:any;
  messageSubscription:Subscription;
  signinForm:FormGroup;
  resetPassForm:FormGroup;

  constructor(private formBuilder: FormBuilder,private authservice:AuthService) { }

  ngOnInit() {
    this.initLoginForm();
    this.initResetForm();
    this.errorSubscription=this.authservice.errorSubject.subscribe((data)=>{
      this.errors=data;
    });
    this.messageSubscription=this.authservice.messageSubject.subscribe((data)=>{
      this.messages=data;
    });
    this.authservice.emitMessage();
    this.authservice.emitError();


  }
  initResetForm(){
      this.resetPassForm=this.formBuilder.group({
          email:['',[Validators.required,Validators.email]]
      });
  }

  initLoginForm(){
    this.signinForm=this.formBuilder.group({
        email:['',[Validators.required,Validators.email]],
        password:['',[Validators.required]]
    });
  }
  onSignIn(){

    const values=this.signinForm.value;
    this.authservice.signin(values);
      setTimeout(()=>{
          this.errors=null;
      },8000);

  }
  onCloseError(){
      this.errors=null;
  }
  onResetPassword(){
      const values=this.resetPassForm.value;
      this.authservice.resetPassword(values);

  }



}
