import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private url = `${environment.API_URL}`;
  private Reservations: any[];
  private Reservation: any;
  private doc: any;
  ReservationSubject = new Subject<any[]>();
  messageSubject = new Subject<any>();
  idSubject = new Subject<any>();
  errorSubject = new Subject<any>();
  private message: string;
  private idReservation: number;
  private error: string;

  constructor(private http: HttpClient, private router: Router) { }

  emitReservations() {
    this.ReservationSubject.next(this.Reservations);
  }

  emitMessage() {
    this.messageSubject.next(this.message);
  }

  emitId() {
    this.idSubject.next(this.idReservation);
  }

  emitError() {
    this.errorSubject.next(this.error);
  }

  getReservations() {
    this.http.get(`${this.url}/reservations`).subscribe((data: any[]) => {
      this.Reservations = data;
      this.emitReservations();
    });
  }

  delete(id) {
    this.http.delete(`${this.url}/Reservations/delete/${id}`).subscribe((data: any) => {
      this.message = data;
      this.emitMessage();
    });
  }

  deleteDocument(id) {
    this.http.delete(`${this.url}/Reservation/document/delete/${id}`).subscribe((data: any) => {
      this.message = data;
      this.emitMessage();
    });
  }

  deletePhoto(id) {
    this.http.delete(`${this.url}/Reservation/photos/delete/${id}`).subscribe((data: any) => {
      this.message = data;
      this.emitMessage();
    });
  }

  changePhoto(file, id) {
    if (file) {
      let formData: FormData = new FormData();
      formData.append('photo', file, file.name);

      const options = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        })
      };

      this.http.post(`${this.url}/Reservations/updateImage/${id}`, formData, options)
        .subscribe(
          data => {
            console.log('success');
          },
          error => console.log(error)
        );
    }
  }

  addDoc(value, img_recto, img_verso) {
    this.http.post(`${this.url}/Reservation/document/add`, value).pipe(
      catchError(this.handleError)
    ).subscribe((data: any) => {
      this.doc = data;
      this.message = data.message;
      this.emitMessage();

      if (data) {
        const id = data.document.id;
        this.uploadImage(id, img_recto, 'recto');
        this.uploadImage(id, img_verso, 'verso');
      }
    });
  }

  private uploadImage(id, image, type) {
    if (image) {
      let formData: FormData = new FormData();
      formData.append(`image_${type}`, image, image.name);

      const options = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        })
      };

      this.http.post(`${this.url}/Reservation/document/${type}/${id}`, formData, options)
        .subscribe(
          data => {
            console.log(`${type} image uploaded successfully`);
          },
          error => console.error(`Error uploading ${type} image`, error)
        );
    }
  }

  addReservation(Reservation) {
    this.http.post(`${this.url}/Reservations/add`, Reservation).pipe(
      catchError(this.handleError)
    ).subscribe((data: any) => {
      this.idReservation = data.Reservation_id;
      this.message = data.message;
      this.emitId();
      this.emitMessage();
    }, error => {
      this.error = error.error;
      this.emitError();
    });
  }

  editReservation(value, id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http.put(`${this.url}/Reservations/update/${id}`, value, options).pipe(
      catchError(this.handleError)
    ).subscribe((data: any) => {
      console.log(data);
      this.message = data.message;
      this.emitMessage();
    }, error => {
      this.error = error;
      console.log(value);
      console.log(error);
      this.emitError();
    });
  }

  editDoc(value, id, img_recto, img_verso) {
    this.http.put(`${this.url}/Reservation/document/update/${id}`, value).pipe(
      catchError(this.handleError)
    ).subscribe((data: any) => {
      this.doc = data;
      this.message = data.message;
      this.emitMessage();

      if (data) {
        this.uploadImage(id, img_recto, 'recto');
        this.uploadImage(id, img_verso, 'verso');
      }
    }, error => {
      this.error = error;
      this.emitError();
    });
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    return throwError('Something went wrong; please try again later.');
  }
}
