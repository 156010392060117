import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
//import { Vehicule } from '../models/tiers.model';
import { TiersService } from "../tiers.service";
import { AuthService } from "../../login/auth.service";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../../environments/environment";
import * as jQuery from 'jquery';
// Define the tiers interface or class here
interface Tiers {
    // Define the properties of the Tiers interface or class
    // For example:
    id: number;
    name: string;
    // Add other properties as needed
  }

@Component({
  selector: 'app-tiers-show',
  templateUrl: './tiers-show.component.html',
  styleUrls: ['./tiers-show.component.css']
})



export class TiersShowComponent implements OnInit {

    constructor(private fb: FormBuilder, private authService: AuthService, private tiersService: TiersService, 
        private activRoute: ActivatedRoute, private http: HttpClient, private ngZone: NgZone, 
        private eltRef: ElementRef) { }

  isSteClient: boolean;
  isSteFour: boolean;
  user_id: number;
  agence_id: number;
  tiers_id: number;
  editDoc: boolean = false;
  editCon: boolean = false;
  tiers: any;
  isfour: any;
  iscli: any;
  message: string = '';
  error: string = '';
  selectedfile1: File = null;
  selectedfile2: File = null;
  selectedfile3: File = null;
  selectedfile4: File = null;
  documentchecked: any[] = [];
  contactchecked: any[] = [];
  selectedDoc: any;
  selectedcontact: any;
  imgVerso: any;
  imgRecto: any;
  TiersDocs: any[] = [];
  TiersContact: any[] = [];

  formTiers: FormGroup;
  formTiers3: FormGroup;
  formTiers4: FormGroup;
  TiersFormDoc: FormGroup;
  TiersFormContact: FormGroup;


  ngOnInit() {
    this.initForm();
    this.user_id = this.authService.AuthUser().id;
    this.agence_id = this.authService.AuthUser().agence_id;
    const id = this.activRoute.snapshot.params['id'];
    this.http.get(`${environment["API_URL"]}` + '/tierss/' + id).subscribe((data: any) => {
      this.tiers = data;
      this.tiers_id = this.tiers.tier.id;
      this.TiersDocs = this.tiers.documents;
      this.TiersContact = this.tiers.contacts;
      this.isfour = this.tiers.tier.isfournisseur;
      this.iscli = this.tiers.tier.isfournisseur;
    });

    this.initFormdoc();
    this.initFormContact();
    this.initFormDocument();
  }

  initForm() {
    this.formTiers = this.fb.group({
      nom: ['', Validators.required],
      prenom:[''],
      adresse_ma:'',
      adresse_etr:'',
      email:[''],
      tel1:'',
      tel2:[''] ,
      ville:[''],
      pays:[''],
      cin:[''],
      raison_social:[''],
      identif_fiscal:'',
      agence_id:[''],
      nationalite:[''],
      info_comp:[''],
      sexe:[''],
      code_postal:[''],
      ice:[''],
      patente:[''],
      fix:[''],
      fax:[''],
      site_web:[''],
      email_soc:[''],
      secteur_activite:[''],
      type_fourniture:[''],
      compagnie_assurance:[''],
      permis:[''],
      date_valid_permis:[''],
      remarques:[''],
      isblocked:[''],
      isclient:[''],
      isassurance:[''],
      isste:[''],
      isfournisseur:[''],
      user_id:[''],
      blacklisted_id:[''],
      agence_id2:[''], // Changed from agence_id to agence_id2
      ice2:[''], // Changed from ice to ice2
      date_naissance:['']
    });
}


    selectSteCli() {
        this.iscli = !this.iscli;
        console.log(this.iscli);
      }
    
      selectSteFour() {
        this.isSteFour = this.isfour;
        console.log(this.isSteFour);
      }


    NonNullForm(value) {
        const val = [value];

        if (val) {

            let filledProps = (val).map(el => {
                // Loop the property names of `el`, creating a new object
                // with the ones whose values aren't `null`.
                // `reduce` is commonly used for doing this:
                return Object.keys(el).reduce((newObj, key) => {
                    const value = el[key];
                    if (value !== "") {
                        newObj[key] = value;
                    }
                    return newObj;
                }, {});
            });
            return filledProps;
        }
        return null
    }



    goTo(url){
      document.location.href='https://'+url;
    }

    initFormDocument(){
        this.TiersFormDoc=this.fb.group({
            type_doc:'',
            num_doc:['',[Validators.required]],
            date_valid:[''],
            tier_id:[''],

        });

    }

    initFormdoc(){

        this.formTiers3=this.fb.group({
            type_doc:'',
            num_doc:['',[Validators.required]],
            date_valid:[''],
            tier_id:[''],

        })
        ;
    }
    initFormContact(){
        this.TiersFormContact=this.fb.group({
            nom:[''],
            prenom:[''],
            fonction:[''],
            tel:[''],
            fix:[''],
            email:[''],
            adresse:[''],
            ville:[''],
            tier_id:['']

        });

    }

    initFormCon(){

        this.formTiers4=this.fb.group({
            nom:['',Validators.required],
            prenom:['',[Validators.required]],
            fonction:[''],
            tel:[''],
            fix:[''],
            email:[''],
            adresse:[''],
            ville:[''],
            tier_id:['']

        })
        ;
    }


    onSubmitForm(){


        this.tiersService.edittiers(this.NonNullForm(this.formTiers.value)[0],this.tiers_id);
        this.ngOnInit();
        this.ngOnInit();
        setTimeout(()=>{
          this.message=null;
        },1000)
    }

    onSubmitForm31(id){

        const value=this.formTiers3.value;
        value['tier_id']=this.tiers_id;

        this.tiersService.editDoc(this.NonNullForm(value)[0],id,this.selectedfile1,this.selectedfile2);
        this.ngOnInit();
        this.ngOnInit();
        setTimeout(()=>{
            this.message=null;
            this.editDoc=false;
        },2000);


    }
    onSubmitForm311(id) {
        const value = this.formTiers4.value;
        value['tier_id'] = this.tiers_id;
      
        this.tiersService.editCon(id, this.NonNullForm(value)[0]); // Pass 'id' as the first argument
        this.ngOnInit();
        this.ngOnInit();
        setTimeout(() => {
          this.message = null;
          this.editCon = false;
        }, 2000);
      }
      

    onSubmitFormDoc(){

        const value3=this.TiersFormDoc.value;
        value3['tier_id']=this.tiers_id;
        console.log(value3);
        this.tiersService.addDoc(value3,this.selectedfile3,this.selectedfile4);
        this.ngOnInit();
        this.ngOnInit();
        setTimeout(()=>{
            this.message=null;

        },2000);

    }
    onSubmitFormContact(){

        const value3=this.TiersFormContact.value;
        value3['tier_id']=this.tiers_id;
        console.log(value3);
        this.tiersService.addCon(value3);
        this.ngOnInit();
        this.ngOnInit();
        setTimeout(()=>{
            this.message=null;

        },2000);

    }
    cancelEdit(){
        this.editDoc=false;
    }
    cancelEdit1(){
        this.editCon=false;
    }



    onFileSelected(event){
        let file1: File = event.target.files[0];
        this.selectedfile1=file1;

    }

    onFileSelected2(event){
        let file1: File = event.target.files[0];
        this.selectedfile2=file1;

    }

    onFileSelected3(event){
        let file1: File = event.target.files[0];
        this.selectedfile3=file1;

    }

    onFileSelected4(event){
        let file1: File = event.target.files[0];
        this.selectedfile4=file1;

    }


    editDocSelected(id){
        this.editDoc=true;
        this.http.get('http://127.0.0.1:8000/api/tierss/document/'+id).subscribe((data:any) => {
            this.selectedDoc= data.Document;
        });
        this.getRecto(id);
        this.getVerso(id);

    }
    editContactSelected(id){
        this.editCon=true;
        this.http.get('http://127.0.0.1:8000/api/tierss/contact/'+id).subscribe((data:any) => {
            this.selectedcontact= data.Contact;
        });

    }

    getVerso(id){
        this.getImage(' http://127.0.0.1:8000/api/tierss/document/verso/'+id).subscribe((data:any) => {
            this.createImageFromBlob2(data);

        },(error)=>{
            this.createImageFromBlob2(null);
            this.imgVerso=null;


        });

    }
    getRecto(id){
        this.getImage(' http://127.0.0.1:8000/api/tierss/document/recto/'+id).subscribe((data:any) => {
            this.createImageFromBlob(data);

        },(error)=>{
            this.createImageFromBlob(null);
            this.imgRecto=null;

        });

    }


    getImage(imageUrl: string): Observable<Blob> {
        return this.http.get(imageUrl, { responseType: 'blob' });
    }
    getImages(imageUrl: string): Observable<Blob> {
        return this.http.get(imageUrl, { responseType: 'blob' });
      }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.imgRecto = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }
    createImageFromBlob2(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.imgVerso = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    onDelete(id){
        let tab=this.documentchecked;
        if(confirm("Voulez vous vraiment Supprimer ces documents")){
            for(let i=0;i<tab.length;i++){
                this.tiersService.deleteDocument(tab[i]);
                this.documentchecked=[];
            }
            this.editDoc=false;
            this.ngOnInit();
            this.ngOnInit();
            setTimeout(()=>{
                this.message=null;

            },5000);
        }
    }

  onDelete1(id){
        let tab=this.contactchecked;
        if(confirm("Voulez vous vraiment Supprimer ces contacts")){
            for(let i=0;i<tab.length;i++){
                this.tiersService.deleteContact(tab[i]);
                this.contactchecked=[];
            }
            this.editCon=false;
            this.ngOnInit();
            this.ngOnInit();
            setTimeout(()=>{
                this.message=null;

            },5000);
        }
    }
    

    checkAll(event) {
        if (event.target.checked) {
            var checked=[];
            jQuery(".checkboxes").each(function () {
                checked.push(parseInt(jQuery(this).val()))

            });
            this.documentchecked=checked;
        }
        if (!event.target.checked){
            this.documentchecked=[];
        }
        console.log(this.documentchecked);
    }
    onCheckboxChagen(event, value) {
        if (!event.target.checked) {
            let index = this.documentchecked.indexOf(value);
            if (index > -1) {
                this.documentchecked.splice(index, 1);
            }
        }
        if (event.target.checked) {
            this.documentchecked.push(value);
        }

    }

    checkAll1(event) {
        if (event.target.checked) {
            var checked=[];
            jQuery(".checkboxes").each(function () {
                checked.push(parseInt(jQuery(this).val()))

            });
            this.contactchecked=checked;
        }
        if (!event.target.checked){
            this.contactchecked=[];
        }
        console.log(this.contactchecked);
    }
    onCheckboxChagen1(event, value) {
        if (!event.target.checked) {
            let index = this.contactchecked.indexOf(value);
            if (index > -1) {
                this.contactchecked.splice(index, 1);
            }
        }
        if (event.target.checked) {
            this.contactchecked.push(value);
        }

    }


}
