import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { AuthService } from "../login/auth.service";
import { TokenService } from "../login/token.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs/internal/Subscription";
import { User } from "../models/user.model";
import { BreadCrumb } from "../models/breadcrumb";
import { MenuService } from "./menu.service";
declare var jQuery: any;
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  loggedIn: boolean;
  user: User;
  name: string;
  title: string;
  subtitle: string;
  menu: any[] = [];
  breadcrumbList: any[] = [];

  constructor(private auth: AuthService, private token: TokenService, private router: Router, private eltRef: ElementRef, private http: HttpClient, private activRoute: ActivatedRoute, private menuService: MenuService) { }

  ngOnInit() {
    this.user = this.auth.AuthUser();
    this.menu = this.menuService.getMenu();
    this.listenRouting();
  }

  ngAfterViewInit() {
    // Ensure that App and Index are properly initialized before calling their methods
    // Example: App.init() and Index.init()
    // Make sure these functions are defined and accessible
    // Check imports and typings for App and Index
  }

  private createDiv(node_name, textElement) {
    let _nodeElement = document.createElement(node_name);
    _nodeElement.innerHTML = textElement;
    return _nodeElement;
  }

  listenRouting() {
    let routerUrl: string, routerList: Array<any>, target: any;
    this.router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        target = this.menu;
        this.breadcrumbList.length = 0;
        routerList = routerUrl.slice(1).split('/');
        routerList.forEach((router, index) => {
          target = target.find(page => page.path.slice(2) === router);
          this.breadcrumbList.push({
            name: target.name,
            path: (index === 0) ? target.path.slice(1) : `${this.breadcrumbList[index - 1].path}/${target.path.slice(2)}`
          });
          if (index + 1 !== routerList.length) {
            target = target.children;
          }
        });
        this.title = this.breadcrumbList[0].name;
        this.subtitle = this.breadcrumbList[this.breadcrumbList.length - 1].name;
      }
    });
  }
}
