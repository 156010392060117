import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {ReparationService} from "../reparation.service";
import {AuthService} from "../../../login/auth.service";
import {environment} from "../../../../environments/environment";
declare var jQuery:any;
@Component({
    selector: 'app-reparation-add',
    templateUrl: './reparation-add.component.html',
    styleUrls: ['./reparation-add.component.css']
})
export class ReparationAddComponent implements OnInit,OnChanges {

    reparationForm: FormGroup;
    @Input() vehicule_id: number;
    @Input() agence_id: number;
    @Input() reparation_id: number;
    @Output() reparationChange: EventEmitter<any> = new EventEmitter<any>();
    message=null;
    error=null;
    user:any;
    reparation:any;
    vehicules:any[];
    agences:any[];
    currentpage:boolean;

    messageSubscription:Subscription;
    errorSubscription:Subscription;
    constructor(private reparationService:ReparationService,private auth:AuthService,private formBuilder:FormBuilder,private http:HttpClient,private router:Router,private _location:Location) { }

    ngOnInit() {
        this.reparationChange.emit(this.reparation_id);
        this.currentpage=this.router.url==='/vehicules/addreparation';
        this.initForm();
        this.formModif();
        this.messageSubscription=this.reparationService.messageSubject.subscribe((message)=>{
            this.message=message;

        });

        this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data:any) => {
            this.agences = data.agences;
        });

        this.errorSubscription=this.reparationService.errorSubject.subscribe((error)=>{

            this.error=error;
        });

        this.user=this.auth.AuthUser();



    }

    initForm(){
        this.message=null;
        this.reparationForm=this.formBuilder.group({
            type_reparation  :['',Validators.required],
            garage:['',Validators.required],
            date_reparation:['',Validators.required],
            date_paiment:[''],
            coordonnees_garage:[''],
            montant:[''],
            mode_reglement:[''],
            num_cheque:[''],
            banque:[''],
            commentaire:[''],
            vehicule_id:['',!this.vehicule_id && !this.reparation_id?[Validators.required]:[]],
            user_id:[''],

            agence_id:[''],
        })
    }

    formModif(){
        if(this.reparation_id){
            this.http.get(`${environment["API_URL"]}`+'/reparations/'+this.reparation_id).subscribe((data:any) => {
                this.reparation = data;
            });
        }

    }

    changeAgence(id){
        this.vehicules=this.agences.find((agence)=>{
            return agence.agence.id === +id;
        }).vehicules;

    }

    onSubmitForm(){
        if(this.vehicule_id && !this.reparation){
            this.reparationForm.value['vehicule_id']=this.vehicule_id;

        }
        this.reparationForm.value['user_id']=this.user.id;

        this.reparationService.addreparation(this.reparationForm.value);

        setTimeout(()=>{
            this.message=null;
            this.message=null;

        },5000);
        if(!this.vehicule_id){
            this.router.navigate(['/vehicules/reparations']);
            jQuery('#wide3').modal('toggle');
        }
        if(this.vehicule_id){
            jQuery('#wide3').modal('toggle');
        }

        this.initForm();


    }

    getback(){
        this._location.back();
    }

    refreshForm(){
        this.error=null;
        this.initForm();
    }

    ngOnChanges(){


        if(this.reparation_id){
            this.http.get(`${environment["API_URL"]}`+'/reparations/'+this.reparation_id).subscribe((data:any) => {
                this.reparation = data;
            });
        }

    }

    NonNullForm(value) {
        const val = [value];

        if (val) {

            let filledProps = (val).map(el => {
                // Loop the property names of `el`, creating a new object
                // with the ones whose values aren't `null`.
                // `reduce` is commonly used for doing this:
                return Object.keys(el).reduce((newObj, key) => {
                    const value = el[key];
                    if (value !== "") {
                        newObj[key] = value;
                    }
                    return newObj;
                }, {});
            });
            return filledProps;
        }
        return null
    }


    onSubmitFormEdit(){
        const value=this.NonNullForm(this.reparationForm.value)[0];
        this.reparationService.editreparation(value,this.reparation_id);
        this.initForm();
        setTimeout(()=>{
            this.message=null;
            this.formModif();
        },2000)

    }

    annulerPaiment(){
        this.reparationForm.value['montant']=null;
        this.reparationForm.value['date_paiment']=null;
        this.reparationForm.value['banque']=null;
        this.reparationForm.value['num_cheque']=null;
        this.reparationForm.value['mode_reglement']=null;


        this.reparationService.editreparationt(this.reparationForm.value,this.reparation_id);
        this.initForm();
        setTimeout(()=>{
            this.message=null;
            this.formModif();
        },2000)

    }

}
