import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable()
export class TokenService {

  constructor() { }

  handle(token){
    this.set(token);
    console.log(this.isValid());
  }

  set(token){
    localStorage.setItem('token',token);
  }

  get(){
    return localStorage.getItem('token');
  }
  remove(){
    return localStorage.removeItem('token');
  }

  isValid(){
    const token=this.get();
    if(token){
      const payload=this.payload(token);
      if(payload){
       return payload.iss == `${environment["API_URL"]}`+'/auth/login' ? true:false;
      }
    }
      return false;
  }

  payload(token){
    const payload=token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload){
    return JSON.parse(atob(payload));
  }

  LoggedIn(){

    return this.isValid();
  }
}
