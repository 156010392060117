import {Component, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
//import {tiers} from "../models/tiers.model";
import {TiersService} from "../tiers/tiers.service";
import {HttpClient} from "@angular/common/http";
import {Subscription} from "rxjs/internal/Subscription";
declare var jQuery:any;
import {environment} from "../../environments/environment";

// Define the tiers interface or class here
interface Tiers {
    // Define the properties of the Tiers interface or class
    // For example:
    id: number;
    name: string;
    // Add other properties as needed
  }
  

@Component({
  selector: 'app-tiers',
  templateUrl: './tiers.component.html',
  styleUrls: ['./tiers.component.css']
})



  

export class TiersComponent implements OnInit {

    

    count: number;
    tiersschecked: any[] = [];
    @Input() check: any;
    @Input() type: any;
    interests: any[];
    tierss: Tiers[]; // Use the defined Tiers interface or class here
    roles: any[];
    agences: any[];
    selectedfile: File;
    formtiers: FormGroup;
    message: null;
    error: null;

    errorSubscription: Subscription;
    messageSubscription: Subscription;

    constructor(private tiersService: TiersService, private eltRef: ElementRef, private http: HttpClient, private ngZone: NgZone, private formBuilder: FormBuilder) {
        jQuery(this.eltRef.nativeElement).ready(function () {
        });
    }
    private initFormWizard() {
        // Initialize Form Wizard plugin here
        // Example:
        // FormWizard.init();
    }
    private initTableManaged() {
        // Initialize TableManaged plugin here
        // Example:
        // TableManaged.init();
    }

    ngOnInit() {

        this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data2:any) => {
            this.agences = data2.agences;
            this.initFormWizard();
            this.initTableManaged();
            console.log(this.agences);

        });

        this.errorSubscription=this.tiersService.errorSubject.subscribe((error)=>{
            this.error=error;
        });
        this.messageSubscription=this.tiersService.messageSubject.subscribe((message)=>{
            this.message=message;
            if(this.message){
                if(this.type){
                    setTimeout(()=>{
                        this.message=null;
                        this.change_type();
                    },800)
                }
                else{
                    setTimeout(()=>{
                        this.message=null;
                        this.ngAfterViewInit();
                    },800)
                }
            }
        });
        setTimeout(()=>{
            this.message=null;
            this.error=null;
        },3000);


    }
    checkAll(event) {
        if (event.target.checked) {
            var checked=[];
            jQuery(".checkboxes").each(function () {
                checked.push(parseInt(jQuery(this).val()))

            });
            this.tiersschecked=checked;
        }
        if (!event.target.checked){
            this.tiersschecked=[];
        }
        console.log(this.tiersschecked);
    }
    onCheckboxChagen(event, value) {
        if (!event.target.checked) {
            let index = this.tiersschecked.indexOf(value);
            if (index > -1) {
                this.tiersschecked.splice(index, 1);
            }
        }
        if (event.target.checked) {
            this.tiersschecked.push(value);
        }

    }
    onDelete(){
        let tab=this.tiersschecked;
        if(confirm("Voulez vous vraiment Supprimer ces Tiers")){
            for(let i=0;i<tab.length;i++){
                this.tiersService.delete(tab[i]);
            }
            this.refresh();
        }
    }

    onActivate(id,value){
        let status={hors_service:value};
        this.tiersService.edittiers(status,id);
        this.refresh();
    }




    ngAfterViewInit(){
        this.http.get(`${environment["API_URL"]}` + '/tierss').subscribe((data: any) => {
            // Handle data retrieval
        
            this.tierss = data.tiers;
            this.count=this.tierss?this.tierss.length:0;

            console.log(data);
            // waiting until select options are rendered
            jQuery("#sample_13").dataTable().fnDestroy();
            this.ngZone.onMicrotaskEmpty.subscribe(() => {
                // App.init();
                //FormWizard.init();
                //TableManaged.init();




                jQuery(this.eltRef.nativeElement).ready(function() {
                    jQuery.fn.dataTable.ext.errMode = 'none';

                    jQuery('#sample_13').on( 'error.dt', function ( e, settings, techNote, message ) {
                        console.log( 'An error has been reported by DataTables: ', message );
                    } ) ;
                });

            });
        });
    }
    refresh(){
        this.message=null;
        this.error=null;
        this.ngAfterViewInit();

    }
    private createDiv(node_name,textElement) {
        var _nodeElement = document.createElement(node_name);
        _nodeElement.innerHTML = textElement;
        return _nodeElement;
    }
    onFileSelected(event){
        let file: File = event.target.files[0];
        this.selectedfile=file;
    }

    change_type(){
        if(this.type==="fournisseur"){
            this.http.get(`${environment["API_URL"]}`+'/tierssFour').subscribe((data:any) => {
                this.tierss = data.tiers;
                this.count=this.tierss?this.tierss.length:0;

                console.log(data);
                // waiting until select options are rendered
                jQuery("#sample_13").dataTable().fnDestroy();
                this.ngZone.onMicrotaskEmpty.subscribe(() => {
                    // App.init();
                    //FormWizard.init();
                    //TableManaged.init();




                    jQuery(this.eltRef.nativeElement).ready(function() {
                        jQuery.fn.dataTable.ext.errMode = 'none';

                        jQuery('#sample_13').on( 'error.dt', function ( e, settings, techNote, message ) {
                            console.log( 'An error has been reported by DataTables: ', message );
                        } ) ;
                    });

                });
            });
        }
        if(this.type==="ste"){
            this.http.get(`${environment["API_URL"]}`+'/tierssSte').subscribe((data:any) => {
                this.tierss = data.tiers;
                this.count=this.tierss?this.tierss.length:0;

                console.log(data);
                // waiting until select options are rendered
                jQuery("#sample_13").dataTable().fnDestroy();
                this.ngZone.onMicrotaskEmpty.subscribe(() => {
                    // App.init();
                    //FormWizard.init();
                    //TableManaged.init();




                    jQuery(this.eltRef.nativeElement).ready(function() {
                        jQuery.fn.dataTable.ext.errMode = 'none';

                        jQuery('#sample_13').on( 'error.dt', function ( e, settings, techNote, message ) {
                            console.log( 'An error has been reported by DataTables: ', message );
                        } ) ;
                    });

                });
            });
        }
        if(this.type==="client"){
            this.http.get(`${environment["API_URL"]}`+'/tierssCli').subscribe((data:any) => {
                this.tierss = data.tiers;
                this.count=this.tierss?this.tierss.length:0;

                console.log(data);
                // waiting until select options are rendered
                jQuery("#sample_13").dataTable().fnDestroy();
                this.ngZone.onMicrotaskEmpty.subscribe(() => {
                    // App.init();
                    //FormWizard.init();
                    //TableManaged.init();




                    jQuery(this.eltRef.nativeElement).ready(function() {
                        jQuery.fn.dataTable.ext.errMode = 'none';

                        jQuery('#sample_13').on( 'error.dt', function ( e, settings, techNote, message ) {
                            console.log( 'An error has been reported by DataTables: ', message );
                        } ) ;
                    });

                });
            });
        }
        if(this.type==="assurance"){
            this.http.get(`${environment["API_URL"]}`+'/tierssAss').subscribe((data:any) => {
                this.tierss = data.tiers;
                this.count=this.tierss?this.tierss.length:0;

                console.log(data);
                // waiting until select options are rendered
                jQuery("#sample_13").dataTable().fnDestroy();
                this.ngZone.onMicrotaskEmpty.subscribe(() => {
                    // App.init();
                    //FormWizard.init();
                    //TableManaged.init();




                    jQuery(this.eltRef.nativeElement).ready(function() {
                        jQuery.fn.dataTable.ext.errMode = 'none';

                        jQuery('#sample_13').on( 'error.dt', function ( e, settings, techNote, message ) {
                            console.log( 'An error has been reported by DataTables: ', message );
                        } ) ;
                    });

                });
            });
        }
        if(this.type==="tout"){
            this.http.get(`${environment["API_URL"]}`+'/tierss').subscribe((data:any) => {
                this.tierss = data.tiers;
                this.count=this.tierss?this.tierss.length:0;

                console.log(data);
                // waiting until select options are rendered
                jQuery("#sample_13").dataTable().fnDestroy();
                this.ngZone.onMicrotaskEmpty.subscribe(() => {
                    // App.init();
                    //FormWizard.init();
                    //TableManaged.init();




                    jQuery(this.eltRef.nativeElement).ready(function() {
                        jQuery.fn.dataTable.ext.errMode = 'none';

                        jQuery('#sample_13').on( 'error.dt', function ( e, settings, techNote, message ) {
                            console.log( 'An error has been reported by DataTables: ', message );
                        } ) ;
                    });

                });
            });
        }
    }

    



}
