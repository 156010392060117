import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
@Injectable()
export class MenuService {
    ids=[];
    id;
    pathuser;

    constructor(private http:HttpClient) {


    }





    getMenu(): any[] {


        let attsUser=[];
        this.http.get(`${environment["API_URL"]}`+'/users/ids').subscribe((data:any) => {
            this.ids = data.ids;


            this.ids.forEach((id)=>{
                let att={
                    name:'',
                    path:'',
                };
                att.name='Profil';
                att.path='./'+id;
                attsUser.push(att);

            });


        });
        let at11={name:'Nouveau Utilisateur',
            path:'./add'};
        attsUser.push(at11);



        let attsVehicule=[];
        this.http.get(`${environment["API_URL"]}`+'/vehicules/ids').subscribe((data:any) => {
            this.ids = data.ids;


            this.ids.forEach((id)=>{
                let att={
                    name:'',
                    path:'',
                };
                att.name='Caracteristques technique';
                att.path='./'+id;
                attsVehicule.push(att);

            });



        });
        let at={name:'Nouveau Vehicule',
            path:'./add'};
        let at1={name:'Assurances',
            path:'./assurances'};
        let at2={name:'Assurer un Vehicule',
            path:'./addassurance'};
        let at3={name:'Pieces Consommables',
            path:'./piecesconsommable'};
        let at4={name:'Reparations',
            path:'./reparations'};
        let at5={name:'Vidanges',
            path:'./vidanges'};
        let at6={name:'Vignettes',
            path:'./vignettes'};
        let at7={name:'Regler une vignette',
            path:'./vignetteadd'};
        let at8={name:'Effectuer une visite technique',
            path:'./visiteadd'};
        let at9={name:'Visites techniques',
            path:'./visites'};

        attsVehicule.push(at);
        attsVehicule.push(at1);
        attsVehicule.push(at2);
        attsVehicule.push(at3);
        attsVehicule.push(at4);
        attsVehicule.push(at5);
        attsVehicule.push(at6);
        attsVehicule.push(at7);
        attsVehicule.push(at8);
        attsVehicule.push(at9);


  let attsAgence=[];
        this.http.get(`${environment["API_URL"]}`+'/agences/ids').subscribe((data:any) => {
            this.ids = data.ids;


            this.ids.forEach((id)=>{
                let att={
                    name:'',
                    path:'',
                };
                att.name='Informations';
                att.path='./'+id;
                attsAgence.push(att);

            });



        });
        let at22={name:'Nouvelle Agence',
            path:'./add'};
        let at222={name:'Lieux de Livraison',
            path:'./lieux'};
        attsAgence.push(at22);
        attsAgence.push(at222);

        let attsTiers=[];
        this.http.get(`${environment["API_URL"]}`+'/tierss/ids').subscribe((data:any) => {
            this.ids = data.ids;


            this.ids.forEach((id)=>{
                let att={
                    name:'',
                    path:'',
                };
                att.name='Informations Tiers';
                att.path='./'+id;
                attsTiers.push(att);

            });


        });
        let at33={name:'Nouveau Tiers',
            path:'./add'};
        attsTiers.push(at33);


        let attsReservation=[];
        this.http.get(`${environment["API_URL"]}`+'/reservations/ids').subscribe((data:any) => {
            this.ids = data.ids;


            this.ids.forEach((id)=>{
                let att={
                    name:'',
                    path:'',
                };
                att.name='Informations de la réservation';
                att.path='./'+id;
                attsReservation.push(att);

            });


        });
        let at44={name:'Nouvelle Reservation',
            path:'./add'};
            attsReservation.push(at44);



        const menu = [
            { name: 'home', path: './', children: [] },
            {
                name: 'Utilisateurs',
                path: './users',
                children:attsUser


            },
            {
                name: 'Agences',
                path: './agences',
                children:attsAgence


            },
            {
                name: 'Vehicules',
                path: './vehicules',
                children:attsVehicule
            },



            {
                name: 'Administration',
                path: './administration',

            },
 {
                name: 'Reservations',
                path: './reservations',
                children:attsReservation

            },

            {
                name: 'Tiers',
                path: './tiers',
                children:attsTiers

            }

                ];



        return menu;
    }

}