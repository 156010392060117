import {AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, Renderer2} from '@angular/core';
import {Agence} from "../../models/agence.model";

import {Subscription} from "rxjs/internal/Subscription";
import {AgenceService} from "./agence.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DOCUMENT} from "@angular/common";
import {UserService} from "../user.service";
import {User} from "../../models/user.model";
import {environment} from "../../../environments/environment";
declare var jQuery:any;

@Component({
  selector: 'app-agence',
  templateUrl: './agence.component.html',
  styleUrls: ['./agence.component.css']
})
export class AgenceComponent implements OnInit,AfterViewInit {

    

    ngAfterViewInit(){
        this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data:any) => {
            this.agences = data.agences;
            this.count=this.agences.length;
            // waiting until select options are rendered
            jQuery("#sample_0").dataTable().fnDestroy();
            this.ngZone.onMicrotaskEmpty.subscribe(() => {
               // App.init();
                //TableManaged.init();

                jQuery(this.eltRef.nativeElement).ready(function() {
                    jQuery.fn.dataTable.ext.errMode = 'none';

                    jQuery('#sample_0').on( 'error.dt', function ( e, settings, techNote, message ) {
                        console.log( 'An error has been reported by DataTables: ', message );
                    } ) ;
                });

            });
        });
}
recharger(){
        this.message=null;
        this.error=null;
        this.agenceschecked=[];
        this.ngAfterViewInit();
}


    count:number=0;
    message=null;
    error=null;
    responsable:any[];
    userSubscription:Subscription;
    messageSubscription:Subscription;
    errorSubscription:Subscription;
    agences:Agence[];
    agenceschecked=[];
    agenceSubscription:Subscription;

    constructor(private agenceService:AgenceService ,private formBuilder:FormBuilder,private http:HttpClient, private eltRef:ElementRef,private ngZone:NgZone,private userService:UserService) {


    }


    ngOnInit() {
       // this.agenceService.getAgences();

       // this.agenceSubscription=this.agenceService.agenceSubject.subscribe((data)=>{
         //   this.agences=data;
        // });
        //this.agenceService.emitAgences();

        this.messageSubscription=this.agenceService.messageSubject.subscribe((message)=>{
            this.message=message;

        });
        this.errorSubscription=this.agenceService.errorSubject.subscribe((error)=>{

            this.error=error;
        });
        setTimeout(()=>{
            this.message=null;
            this.error=null;
        },3000);


    }


    checkAll(event) {
        if (event.target.checked) {
            var checked=[];
            jQuery(".checkboxes").each(function () {
                checked.push(parseInt(jQuery(this).val()))

            });
            this.agenceschecked=checked;
        }
        if (!event.target.checked){
            this.agenceschecked=[];
        }
        console.log(this.agenceschecked);
    }

    onCheckboxChagen(event, value) {

        if (!event.target.checked) {
            let index = this.agenceschecked.indexOf(value);
            if (index > -1) {
                this.agenceschecked.splice(index, 1);
            }
        }
        if (event.target.checked) {
            this.agenceschecked.push(value);
        }
        console.log(this.agenceschecked);
    }

    onDelete(){
        let tab=this.agenceschecked;
        if(confirm("Voulez vous vraiment Supprimer ces Agences")){
            for(let i=0;i<tab.length;i++){
                this.agenceService.delete(tab[i]);
                this.recharger();

            }

        }
    }






}
