import { Component, OnInit } from '@angular/core';
import { TokenService } from "../login/token.service";
import { AuthService } from "../login/auth.service";
import { Router } from "@angular/router";
import { User } from "../models/user.model";
import { Observable } from "rxjs/internal/Observable";
import { HttpClient } from "@angular/common/http";
//import { EventEmitterService } from "../event-emitter.service"; // Import the service
import { environment } from "../../environments/environment";
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EventEmitterService {
  private getUserSubject = new Subject<any>();
  userObservable = this.getUserSubject.asObservable();
  subsVar: any; // Define subsVar property
  invokeChangePhoto: Subject<any> = new Subject<any>(); // Define invokeChangePhoto property

  constructor() {}

  onGetUser() {
    this.getUserSubject.next();
  }
}

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css']
})
export class TopNavbarComponent implements OnInit {
  loggedIn: boolean;
  use: User;
  user: User;
  imageToShow: any;
  logo: any;

  constructor(private token: TokenService, private auth: AuthService, private router: Router, private http: HttpClient, private eventEmitterService: EventEmitterService) { } // Inject the service

  ngOnInit() {
    this.auth.authStatus.subscribe((value) => {
      this.loggedIn = value;
    });
    this.user = this.auth.AuthUser();

    this.getImage(`${environment["API_URL"]}` + '/usersPhoto/' + this.user.id).subscribe((data) => {
      this.createImageFromBlob(data);
    });
    this.getImage(`${environment["API_URL"]}` + '/parameters/logo').subscribe((data) => {
      this.createLogoFromBlob(data);
    });

    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.invokeChangePhoto.subscribe(() => {
        this.ngOnInit();
      });
    }
  }

  refreshUser(id) {
    this.eventEmitterService.onGetUser();
    this.router.navigate(['/users/' + id]);
  }

  logOut(event: MouseEvent) {
    this.token.remove();
    this.auth.removeAuthUser();
    this.auth.changeAuthStatus(false);
    this.router.navigate(['/login']);
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  createLogoFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.logo = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  refresh() {
    this.ngOnInit();
  }
}
