import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AgenceService } from "../agence.service";
import { Agence } from "../../../models/agence.model";
import { Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Location } from '@angular/common';
import { environment } from "../../../../environments/environment";
declare var jQuery: any;

@Component({
  selector: 'app-agence-show',
  templateUrl: './agence-show.component.html',
  styleUrls: ['./agence-show.component.css']
})





export class AgenceShowComponent implements OnInit {
  constructor(
    private activRoute: ActivatedRoute,
    private ngZone: NgZone,
    private eltRef: ElementRef,
    private agenceService: AgenceService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private _location: Location
  ) { }

  agence: Agence;
  imageToShow: any;
  agenceForm: FormGroup;
  users: any[];
  messageSub: Subscription;
  errorSub: Subscription;
  message: string | null = null;
  count: number | null = null;
  error: string | null = null;
  responsable: any[];

  //agence: Agence;

  ngOnInit() {
    const id = this.activRoute.snapshot.params['id'];
    this.http.get(`${environment["API_URL"]}/agences/${id}`).subscribe(
      (data: any) => {
        this.agence = data;
        this.users = data.users;
        this.count = this.users ? this.users.length : 0;
        console.log(this.users);
        jQuery("#sample_1").dataTable().fnDestroy();
        this.ngZone.onMicrotaskEmpty.subscribe(() => {
          //TableManaged.init();

          jQuery(this.eltRef.nativeElement).ready(function () {
            jQuery.fn.dataTable.ext.errMode = 'none';

            jQuery('#sample_1').on('error.dt', function (e, settings, techNote, message) {
              console.log('An error has been reported by DataTables: ', message);
            });
          });
        });
      },
      (error) => {
        console.log(error);
      }
    );
    this.getUsers();
    this.messageSub = this.agenceService.messageSubject.subscribe((data) => {
      this.message = data;
    });
    this.errorSub = this.agenceService.errorSubject.subscribe((data) => {
      this.error = data;
    });
    this.initForm();
    setTimeout(() => {
      this.message = null;
    }, 3000);
  }

  initForm() {
    this.agenceForm = this.formBuilder.group({
      nom: '',
      tel: '',
      fax: '',
      email: '',
      ville: '',
      pays: '',
      responsable_id: '',
      code_postal: '',
      adresse: ''
    });
  }

  getUsers() {
    this.http.get(`${environment["API_URL"]}/users`).subscribe((data: any) => {
      this.responsable = data.users;
      console.log(data);
    });
  }

  getback() {
    this._location.back();
  }

  NonNullForm(value) {
    const val = [value];

    if (val) {
      let filledProps = val.map(el => {
        return Object.keys(el).reduce((newObj, key) => {
          const value = el[key];
          if (value !== "") {
            newObj[key] = value;
          }
          return newObj;
        }, {});
      });
      return filledProps;
    }
    return null;
  }

  onSubmitForm() {
    const value = this.NonNullForm(this.agenceForm.value)[0];
    if (value) {
      // Assuming 'agence' has a property 'id'
      this.agenceService.editAgence(value, this.agence.id);
      setTimeout(() => this.ngOnInit(), 100);
    }
  }

  recharger() {
    this.ngOnInit();
  }

  refreshForm() {
    this.initForm();
  }
}
