
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../login/auth.service";
import { ReservationService } from "../reservation.service";
import { HttpClient } from "@angular/common/http";
import { Brands } from "../../vehicule/brands";
import { environment } from "../../../environments/environment";
import moment from 'moment'; // Change the import statement
declare var $: any;


@Component({
    selector: 'app-reservation-add',
    templateUrl: './reservation-add.component.html',
    styleUrls: ['./reservation-add.component.css']
})
export class ReservationAddComponent implements OnInit,AfterViewInit {
    isClient:boolean=true;
    date_debut=null;
    date_fin=null;
    timeBetween;
    isSte:boolean=false;
    isAssurance:boolean=false;
    isSteClient:boolean=true;
    isSteFour:boolean=false;
    user_id:number;
    agence_id:number;
    count:number;
    vehiculesSource:any[];
    vehicules:any[];
    reservations:any[];
    agences:any[];
    models:any[];
    marques:any[];
    marquesWithV:any[];
    topcat:any[];
    @Input() mark;

    formReservation:FormGroup;
    constructor(private brands:Brands,private fb:FormBuilder,private authService:AuthService,private reservationService:ReservationService,private http:HttpClient ) { }

    ngOnInit() {
        this.initDateTimePicker(); // Call the initialization method
        this.http.get(`${environment["API_URL"]}`+'/vehicules').subscribe((data:any) => {
            this.vehiculesSource = data.Vehicules;
            this.vehicules=this.vehiculesSource;

            this.count=this.vehicules?this.vehicules.length:0;});
        this.http.get('http://127.0.0.1:8000/api/reservations').subscribe((data:any) => {
            this.reservations = data.Reservations;


            });
        this.http.get(`${environment["API_URL"]}`+'/agences').subscribe((data:any)=> {
            this.agences = data.agences;

        });
        this.http.get(`${environment["API_URL"]}`+'/vehiculesMarques').subscribe((data:any)=> {
            this.marques=Object.keys(data.Vehicules);
            this.marquesWithV=data.Vehicules;


        });
        this.http.get(`${environment["API_URL"]}`+'/vehiculesTopCat').subscribe((data:any)=> {
            this.topcat=data.topCat;

        });


      this.initDateRange();
      this.initDateTimePicker();
      //this.initMoneySlider();
      this.initFormReservation();

    }
    ngAfterViewInit(){
        $(document).ready(function(){
            $("#myInput").on("keyup", function() {
                var value = $(this).val().toLowerCase();
                $("#myDIV ").filter(function() {
                    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
                });
            });
        });
    }

    initDateTimePicker() {

        
        $(function($) {
            var date2;
            var date1;
            const formattedDate = moment().format('YYYY-MM-DD');
            $(".form_datetime").datetimepicker({
                autoclose: true,
                lang: 'fr',
                format: "yyyy-mm-dd hh:ii",
                pickerPosition: "bottom-left", // Removed reference to App.isRTL()
                DaysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                weekStart: 1,
                days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
                daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
                daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
                months: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
                monthsShort: ["janv.", "févr.", "mars", "avril", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
                today: "Aujourd'hui",
                monthsTitle: "Mois"
            }).on('change', function(e){
                date1 = new Date(e.target.value);
                date2 = date2 ? date2 : date1;
                console.log((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
                if ((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) > 0 && (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) < 7) {
                    $("#pjour").show();
                    $("#psemaine").hide();
                    $("#pmois").hide();
                } else if ((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) >= 7 && (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) < 30) {
                    $("#pjour").hide();
                    $("#psemaine").show();
                    $("#pmois").hide();
                } else if ((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) >= 30) {
                    $("#pjour").hide();
                    $("#psemaine").hide();
                    $("#pmois").show();
                } else {
                    $("#pjour").hide();
                    $("#psemaine").hide();
                    $("#pmois").hide();
                }
            });
    
            $(".form_datetime2").datetimepicker({
                autoclose: true,
                lang: 'fr',
                format: "yyyy-mm-dd hh:ii",
                pickerPosition: "bottom-left", // Removed reference to App.isRTL()
                DaysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                weekStart: 1,
                days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
                daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
                daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
                months: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
                monthsShort: ["janv.", "févr.", "mars", "avril", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
                today: "Aujourd'hui",
                monthsTitle: "Mois"
            }).on('change', function(e){
                date2 = new Date(e.target.value);
                date1 = date1 ? date1 : new Date();
    
                if ((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) > 0 && (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) < 7) {
                    $("#pjour").show();
                    $("#psemaine").hide();
                    $("#pmois").hide();
                } else if ((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) >= 7 && (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) < 30) {
                    $("#pjour").hide();
                    $("#psemaine").show();
                    $("#pmois").hide();
                } else if ((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) >= 30) {
                    $("#pjour").hide();
                    $("#psemaine").hide();
                    $("#pmois").show();
                } else {
                    $("#pjour").hide();
                    $("#psemaine").hide();
                    $("#pmois").hide();
                }
            });
        });
    }
    
    
   /*  initMoneySlider(){
        UIIonSliders.init();
    } */

    dateDiff(date1){
        const a=new Date(date1);
        const b=new Date();
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects

        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());


        return Math.floor((utc2 - utc1) / _MS_PER_DAY)+' jours ';
    }

    
    initDateRange(){
     var dateRanges = [{
         'start': moment('2019-10-10'),
         'end': moment('2019-10-15')
     },
         {
             'start': moment('2019-10-25'),
             'end': moment('2019-10-30')
         },
         {
             'start': moment('2019-11-10'),
             'end': moment('2019-11-15')
         },
         {
             'start': moment('2019-11-25'),
             'end': moment('2019-11-30')
         },
         {
             'start': moment('2019-12-10'),
             'end': moment('2019-12-15')
         },
         {
             'start': moment('2019-12-25'),
             'end': moment('2019-12-30')
         },
         {
             'start': moment('2019-01-10'),
             'end': moment('2019-01-15')
         },
         {
             'start': moment('2019-01-25'),
             'end': moment('2019-01-30')
         },
         {
             'start': moment('2019-02-10'),
             'end': moment('2019-02-15')
         },
         {
             'start': moment('2019-02-25'),
             'end': moment('2019-02-30')
         }
     ];

     function isInvalidDate(date, log) {
         return dateRanges.reduce(function(bool, range) {
//     if (log && date >= range.start && date <= range.end) {
//       console.log(date, range);
//     }
             return bool || (date >= range.start && date <= range.end);
         }, false);
     }

     $(function($) {
         $("#daterange").daterangepicker({
             timePicker: true,
             timePicker24Hour:true,
             minDate: moment(),
             buttonClasses: ['btn'],
             applyClass: 'btn-success',
             cancelClass: 'btn-default',
             format: 'DD/MM/YYYY',

             locale: {
                 format: 'dddd D MMMM YYYY à HH:mm',
                 applyLabel: 'Reserver',
                 fromLabel: 'Du',
                 toLabel: 'Au ',
                 separator: '  - Au -  ',
                 customRangeLabel: 'Custom Range',
                 daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                 monthNames: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'September', 'October', 'November', 'December'],
                 firstDay: 1
             },
             isInvalidDate: isInvalidDate
         }, function(start, end, label) {
             var temp = new Date(start);
             var endDate = new Date(end);
             var invalid = false;
             while (temp.getTime() < endDate.getTime()) {
                 if (isInvalidDate(temp, true)) {
                     invalid = true;
                 }
                 temp.setDate(temp.getDate() + 1);
             }

             if (invalid) {
                 alert('invalid')
             }
         });
     });

     $('#daterange').on('apply.daterangepicker', function(ev, picker) {
         console.log(picker.startDate.format('YYYY-MM-DD HH:mm:ss'));
         console.log(picker.endDate.format('YYYY-MM-DD HH:mm:ss'));
     });
     $('#daterange').on('cancel.daterangepicker', function(ev, picker) {
         //do something, like clearing an input
         $('#daterange').val('');
     });
 }

    initFormReservation(){
        this.formReservation=this.fb.group({
            boite_vitesse:[''],
            carburant:[''],
            marque:[''],


            modele:[''],
            portes:[''],
            passagers:[''] ,
            climatise:false,
            couleur:[''],
            agence_id:[''],
            roue_secour:false,
            categorie:[''],

        })

    }



    onSubmitFormReservation(){
        this.vehicules=this.vehiculesSource;
        let date_debut= new Date($(".form_datetime").find("input").val());
        let date_fin= new Date($(".form_datetime2").find("input").val());
        let values=$("#range_1").ionRangeSlider()[0].value.split(';');
        let from1=values[0];
        let to1=values[1];

        let values2=$("#range_12").ionRangeSlider()[0].value.split(';');
        let from2=values2[0];
        let to2=values2[1];

        let values3=$("#range_13").ionRangeSlider()[0].value.split(';');
        let from3=values3[0];
        let to3=values3[1];


        this.vehicules=this.filtrePriceJ(this.vehicules,from1,to1);
        this.vehicules= this.filtrePriceS(this.vehicules,from2,to2);
        this.vehicules=this.filtrePriceM(this.vehicules,from3,to3);


        if(date_debut && date_fin){
        this.vehicules=this.filtreRes(this.vehicules,date_debut,date_fin);
        }
        if(this.formReservation.value['agence_id']){
                this.vehicules=this.filtreAgence(this.vehicules);
        }
        if(this.formReservation.value['categorie']){
                this.vehicules=this.filtreCat(this.vehicules);
        }
        if(this.formReservation.value['marque']){
                this.vehicules=this.filtreMarque(this.vehicules);
        }
        if(this.formReservation.value['modele']){
                this.vehicules=this.filtreModele(this.vehicules);
        }
        if(this.formReservation.value['marque']){
                this.vehicules=this.filtreMarque(this.vehicules);
        }
        if(this.formReservation.value['portes']){
                this.vehicules=this.filtrePortes(this.vehicules);
        }
        if(this.formReservation.value['carburant']){
                this.vehicules=this.filtreCarburant(this.vehicules);
        }
        if(this.formReservation.value['portes']){
                this.vehicules=this.filtrePortes(this.vehicules);
        }
        if(this.formReservation.value['passagers']){
                this.vehicules=this.filtrePassagers(this.vehicules);
        }
        if(this.formReservation.value['climatise']){
                this.vehicules=this.filtreClim(this.vehicules);
        }
        if(this.formReservation.value['roue_secour']){
                this.vehicules=this.filtreRoue(this.vehicules);
        }
        if(this.formReservation.value['boite_vitesse']){
                this.vehicules=this.filtreBV(this.vehicules);
        }

        this.count=this.vehicules.length;

    }

     filtreRes(vehiculo,date_debut,date_fin){

         let obj = vehiculo.filter(obj => (obj.reservation.length == 0 || obj.reservation.filter(obj2=>((new Date(obj2.date_depart) > date_debut && new Date(obj2.date_depart) < date_fin) || (date_debut >new Date(obj2.date_depart) && date_debut< new Date(obj2.date_retour)))).length==0 ));
         obj= obj== undefined?[]:obj;

         return obj;
     }

     filtreAgence(vehiculo){

         let obj = vehiculo.filter(obj => obj.vehicule.agence_id === +this.formReservation.value['agence_id']);
         obj= obj== undefined?[]:obj;

         return obj;
     }
     filtreCarburant(vehiculo){

         let obj = vehiculo.filter(obj => obj.vehicule.energie === this.formReservation.value['carburant']);
         obj= obj== undefined?[]:obj;

         return obj;
     }
     filtreBV(vehiculo){

         let obj = vehiculo.filter(obj => obj.vehicule.boite_vitesse === this.formReservation.value['boite_vitesse']);
         obj= obj== undefined?[]:obj;

         return obj;
     }
     filtrePortes(vehiculo){

         let obj = vehiculo.filter(obj => +this.formReservation.value['portes']< 4 ? obj.vehicule.portes < +this.formReservation.value['portes']:obj.vehicule.portes > +this.formReservation.value['portes']);
         obj= obj== undefined?[]:obj;

         return obj;
     }
     filtrePassagers(vehiculo){

         let obj = vehiculo.filter(obj => +this.formReservation.value['passagers']< 8 ? obj.vehicule.passagers < +this.formReservation.value['passagers']:obj.vehicule.passagers > +this.formReservation.value['passagers']);
         obj= obj== undefined?[]:obj;

         return obj;
     }

     filtreClim(vehiculo){

         let obj = vehiculo.filter(obj => obj.vehicule.climatise == this.formReservation.value['climatise']);
         obj= obj== undefined?[]:obj;

         return obj;
     }

     filtreRoue(vehiculo){

         let obj = vehiculo.filter(obj => obj.vehicule.roue_secour == this.formReservation.value['roue_secour']);
         obj= obj== undefined?[]:obj;

         return obj;
     }

     filtreMarque(vehiculo){

         let obj = vehiculo.filter(obj => obj.vehicule.marque === this.formReservation.value['marque']);
         obj= obj== undefined?[]:obj;

         return obj;
     }

     filtreModele(vehiculo){

         let obj = vehiculo.filter(obj => obj.vehicule.modele === this.formReservation.value['modele']);
         obj= obj== undefined?[]:obj;

         return obj;
     }

     filtreCat(vehiculo){

         let obj = vehiculo.filter(obj => obj.vehicule.categorie === this.formReservation.value['categorie']);
         obj= obj== undefined?[]:obj;

         return obj;
     }


     filtrePriceJ(vehiculo,from,to){

         let obj = vehiculo.filter(obj => obj.vehicule.prix_particulier >= from && obj.vehicule.prix_particulier <= to) ;
         obj= obj== undefined?[]:obj;

         return obj;

     }

     filtrePriceS(vehiculo,from,to){

         let obj = vehiculo.filter(obj => obj.vehicule.prix_particulier_sem >= from && obj.vehicule.prix_particulier_sem <= to) ;
         obj= obj== undefined?[]:obj;

         return obj;

     }
     filtrePriceM(vehiculo,from,to){


         let obj = vehiculo.filter(obj => obj.vehicule.prix_particulier_mo >= from && obj.vehicule.prix_particulier_mo <= to) ;
         obj= obj== undefined?[]:obj;

         return obj;

     }
    getModels (marque){

       this.models=this.marquesWithV[marque];


    }


}