
import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {TokenService} from "../login/token.service";
import {Observable} from "rxjs/internal/Observable";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardBeforeService implements CanActivate{

    constructor(private token:TokenService,private router:Router) { }

    canActivate(route:ActivatedRouteSnapshot , state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if(!this.token.LoggedIn())
        {return !this.token.LoggedIn();}
        else
        {this.router.navigate(['']);
        return false;}

    }
}
