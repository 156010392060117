import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../auth.service";
import {Subscription} from "rxjs/internal/Subscription";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  errors=null;
    rpassword:null;
  errorSubscription:Subscription;
  form={
     email:null,
     password:null,
      token:null
  }


  constructor(private route:ActivatedRoute, private authservice:AuthService) {
    this.route.queryParams.subscribe((params)=>
    {
      this.form.token=params['token'];

    })
  }

  ngOnInit() {
    this.errorSubscription=this.authservice.errorSubject.subscribe((error)=>{
      this.errors=error.error.error;
    })
  }
  onChangePassword(){
          const p1=this.form.password;
          const p2=this.rpassword;
          if(p1==p2){
            this.authservice.changePassword(this.form);
          }
          else{
            this.errors="veuillez bien reconfirmer votre mot de passe";
          }

  }



}
