import { Injectable } from '@angular/core';
import { User } from "../models/user.model";
import { Subject } from "rxjs/internal/Subject";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private users: any[];
  private user: any;
  userSubject = new Subject<any[]>();
  messageSubject = new Subject<any>();
  errorSubject = new Subject<any>();
  private message: string;
  private error: string;
  private url = `${environment["API_URL"]}`;

  constructor(private http: HttpClient) { }

  emitUsers() {
    this.userSubject.next(this.users);
  }

  emitMessage() {
    this.messageSubject.next(this.message);
  }

  emitError() {
    this.errorSubject.next(this.error);
  }

  getUsers() {
    this.http.get(this.url + '/users').subscribe((data: any[]) => {
      this.users = data;
      this.emitUsers();
      console.log(this.users);
    });
  }

  delete(id) {
    this.http.delete(this.url + '/users/delete/' + id).subscribe((data: any) => {
      this.message = data;
      this.emitMessage();
    });
  }

  changePhoto(file, id) {
    if (file) {
      let formData: FormData = new FormData();
      formData.append('photo', file, file.name);

      const headers = new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.token
      });

      let options = { headers: headers };
      this.http.post(`${environment["API_URL"]}` + '/users/updateImage/' + id, formData, options)
        .subscribe(
          data => {
            console.log('success');
          },
          error => console.log(error)
        );
    }
  }

  removePhoto(id) {
    this.http.get(`${environment["API_URL"]}` + '/users/removeImage/' + id)
      .subscribe(
        (data: any) => {
          this.message = data.message;
          this.emitMessage();
          console.log(data);
        },
        error => console.log(error)
      );
  }

  addUser(user, file) {
    this.http.post(`${environment["API_URL"]}` + '/users/add', user).subscribe((data: any) => {
      this.user = data;
      this.message = data.message;
      this.emitMessage();

      if (data) {
        user.id = data.user.id;

        if (file) {
          let formData: FormData = new FormData();
          formData.append('photo', file, file.name);

          const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.token
          });

          let options = { headers: headers };
          this.http.post(`${environment["API_URL"]}` + '/users/updateImage/' + data.user.id, formData, options)
            .subscribe(
              data => {
                console.log('success');
              },
              error => console.log(error)
            )
        }
      }

    }, error => {
      this.error = error;
      this.emitError();

    });

  }

  editUser(value, id) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    let options = { headers: headers };
    this.http.put(`${environment["API_URL"]}` + '/users/update/' + id, value, options).subscribe((data: any) => {
      this.message = data.message;
      this.emitMessage();
    }, (error) => {
      this.error = error;
      console.log(value);
      console.log(error);
      this.emitError();
    })
  }

}
