import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  private url = `${environment["API_URL"]}`;
  private message: string;
  private error: string;
  messageSubject = new Subject<any>();
  errorSubject = new Subject<any>();

  constructor(private http: HttpClient) { }

  addRole(role) {
    this.http.post(this.url + 'role', role).subscribe((data: any) => {
      this.message = data.message;
    }, (error) => {
      this.error = error;
    });
  }

  emitError() {
    this.errorSubject.next(this.error);
  }

  emitMessage() {
    this.messageSubject.next(this.message);
  }

  editAdmin(value) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.http.put('http://127.0.0.1:8000/api/parameters/update', value, { headers }).subscribe((data: any) => {
      this.message = data.message;
      this.emitMessage();
    }, (error) => {
      this.error = error;
      console.log(value);
      console.log(error);
      this.emitError();
    });
  }

  editLogo(file: File) {
    if (file) {
      const formData: FormData = new FormData();
      formData.append('logo', file, file.name);

      const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.token });
      this.http.post('http://127.0.0.1:8000/api/parameters/updateLogo', formData, { headers })
        .subscribe(
          data => {
            console.log('success');
          },
          error => {
            console.log(error);
          }
        );
    }
  }
}
