import { AfterViewInit, Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AdministrationService } from "./administration.service";
import { Location } from "@angular/common";
import { EventEmitterService } from "../event-emitter.service";
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit, AfterViewInit {

  constructor(private http: HttpClient, private eventEmitterService: EventEmitterService, private eltRef: ElementRef, private ngZone: NgZone, private formBuilder: FormBuilder, private adminService: AdministrationService, private _location: Location) { }

  roles: any[];
  rights: any[];
  roleForm: FormGroup;
  formParameter1: FormGroup;
  formParameter2: FormGroup;
  formParameter3: FormGroup;
  formParameter4: FormGroup;
  formParameter5: FormGroup;
  admin: any;
  selectedfile: File;

  ngAfterViewInit() {}

  ngOnInit() {
    this.getAdmin();
    this.http.get(`${environment["API_URL"]}` + '/roles').subscribe((data: any) => {
      this.roles = data.roles;
    });

    this.initForm();
    this.initFormParameter1();
    this.initFormParameter2();
    this.initFormParameter3();
    this.initFormParameter4();
    this.initFormParameter5();
  }

  initForm() {
    this.roleForm = this.formBuilder.group({
      role_name: ['', Validators.required],
      description: [''],
      right_id: this.formBuilder.array([this.createRight()]),
    })
  }

  initFormParameter1() {
    this.formParameter1 = this.formBuilder.group({
      raison_social: [''],
      adresse: [''],
      email: [''],
      tel: [''],
      fax: [''],
      ville: [''],
      pays: [''],
      siteweb: ['']
    });
  }

  initFormParameter2() {
    this.formParameter2 = this.formBuilder.group({
      capital: [''],
      rc: [''],
      ice: [''],
      if: [''],
      cnss: [''],
      patente: [''],
      devis: [''],
    });
  }

  initFormParameter3() {
    this.formParameter3 = this.formBuilder.group({
      alert_vidange: [''],
      alert_chaine: [''],
      alert_visite: [''],
    });
  }

  initFormParameter4() {
    this.formParameter4 = this.formBuilder.group({
      lien_api: [''],
    });
  }

  initFormParameter5() {
    this.formParameter5 = this.formBuilder.group({
      MAIL_HOST: [''],
      MAIL_PORT: [''],
      MAIL_USERNAME: [''],
      MAIL_PASSWORD: [''],
      MAIL_ENCRYPTION: [''],
    });
  }

  fetchDataWithHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + 'your_token_here' // Replace 'your_token_here' with your actual token
    });

    this.http.get<any>(`${environment["API_URL"]}` + '/parameters/show', { headers: headers }).subscribe(
      (data: any) => {
        this.admin = data.administration;
      },
      (error) => {
        // Handle error
      }
    );
  }

  getAdmin() {
    this.fetchDataWithHeaders();
  }

  createRight(): FormGroup {
    return this.formBuilder.group({
      right_name: '',
      edit: '',
      add: '',
      delete: '',
      archiver: '',
      show: '',
      description: '',
    });
  }

  onFileSelected(event) {
    let file: File = event.target.files[0];
    this.selectedfile = file;
    console.log(this.selectedfile)
  }

  onSubmitForm() {}

  changePhoto() {
    this.eventEmitterService.onChangePhoto();
  }
}
